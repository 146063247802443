export function quantityAdd() {
  var getJson = localStorage.getItem("persist:mk-store");
  if (getJson) {
    let data = JSON.parse(getJson);
    var jsonString = JSON.parse(data.cartData);
    const idArray = jsonString.map((item) => ({
      id: item.id,
      quantity: 1,
      price: item.sell_price,
      productName: item.name,
    }));
    var updateQuantity = JSON.parse(localStorage.getItem("MKquantity"));
      //  console.log("=======>>yyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyy",idArray[0].quantity)   
      //  console.log("=======>>ooooooooooooooooooooooooooooooo",updateQuantity)  
       const updateQuantityShow = [];
  
       if (updateQuantity) {
        idArray.forEach((item, index) => {
          const matchingUpdate = updateQuantity.find(updateItem => updateItem.id === item.id);
          if (matchingUpdate && matchingUpdate.quantity > 1) {
            idArray[index].quantity = matchingUpdate.quantity;
            updateQuantityShow.push({ id: matchingUpdate.id, quantity: matchingUpdate.quantity });
          }
        });
      }
      
       
       console.log(idArray);
       console.log(updateQuantityShow);
        localStorage.setItem("MKquantity", JSON.stringify(idArray));
    console.log(idArray);
    return idArray
  }
}

export function quantityShow (arr) {
   localStorage.setItem("MKquantity", JSON.stringify(arr));
//    var quantity = JSON.parse(localStorage.getItem("MKquantity"));
//    return quantity
  }
  

  export function quantityShowData () {
    var quantity = JSON.parse(localStorage.getItem("MKquantity"));
    return quantity
   }