import React from 'react'
import logo1 from "../viewer_assets/img/icons/orders.png"
import logo2 from "../viewer_assets/img/icons/wishlist.png"
import logo3 from "../viewer_assets/img/icons/account.png"
import logo6 from "../viewer_assets/img/icons/address.png"
import logo4 from "../viewer_assets/img/icons/settings.png"
import logo5 from "../viewer_assets/img/icons/logout.png"
import { Link,useNavigate  } from 'react-router-dom'
import { toastSuccess } from "../utils/notifyCustom"
const Dashboard = () => {
    const navigate = useNavigate();
    const handlelogout = () =>{
        localStorage.removeItem("MKapi_key");
        localStorage.removeItem("MKuserId");
        toastSuccess("successfully logged out")
        navigate('/')
    }
    return (
        <div>
            <main>
                {/* cart-area-start */}
                <section className="cart-area py-lg-5 py-md-5 py-3">
                    <div className="container">
                        <div className="row">
                            <div className="offset-lg-2 col-lg-8 col-md-12">
                                <ul className="user_side_nav">
                                    <li>
                                        <Link to={"/orders"}>
                                            <img src={logo1} />
                                            <span>
                                                <h6>Your Orders</h6>Track, Return or view order history
                                            </span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={"/wishlist"}>
                                            <img src={logo2} />
                                            <span>
                                                <h6>Your Wishlists</h6>View or order your saved items
                                            </span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={"/account"}>
                                            <img src={logo3} />
                                            <span>
                                                <h6>Your Account</h6>Edit name, email, mobile number
                                            </span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={"/address"}>
                                            <img src={logo6} />
                                            <span>
                                                <h6>Your Address</h6>Manage all your address here
                                            </span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={"/change_password"}>
                                            <img src={logo4} />
                                            <span>
                                                <h6>Change Password</h6>Change your password here
                                            </span>
                                        </Link>
                                    </li>
                                    <li>
                                        <a onClick={handlelogout}>
                                            <img src={logo5} />
                                            <span>
                                                <h6>Logout</h6>Logout your account
                                            </span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
                {/* cart-area-end */}
            </main>

        </div>
    )
}

export default Dashboard