import React from 'react'

const Address = () => {
  return (
    <main>
  {/* cart-area-start */}
  <section className="cart-area py-lg-5 py-md-5 py-3">
    <div className="container">
      <div className="row">
        <div className="offset-lg-3 col-lg-6 col-md-12">
          <h5 className="mb-3">Your Address</h5>
          <div className="order_grid">
            <div className="order_box formTogle">
              <div className="">
                <h6 className="mb-2">
                  <i className="fas fa-map-marker-alt" /> Home
                </h6>
                <h6 className="mb-0">Raigachi, Rajarhat, North 24 Parganas</h6>
              </div>
              <div>
                <a
                  href="javascript:void(0)"
                  onclick="$('.formTogle').toggle()"
                  style={{ fontSize: 15 }}
                  className="mb-0 text-primary"
                >
                  Edit
                </a>
                <a
                  href="javascript:void(0)"
                  style={{ fontSize: 15 }}
                  className="mb-0 text-danger ms-1"
                >
                  Delete
                </a>
              </div>
            </div>
            <div className="order_box formTogle">
              <div className="">
                <h6 className="mb-2">
                  <i className="fas fa-map-marker-alt" /> Office
                </h6>
                <h6 className="mb-0">Raigachi, Rajarhat, North 24 Parganas</h6>
              </div>
              <div>
                <a
                  href="javascript:void(0)"
                  onclick="$('.formTogle').toggle()"
                  style={{ fontSize: 15 }}
                  className="mb-0 text-primary"
                >
                  Edit
                </a>
                <a
                  href="javascript:void(0)"
                  style={{ fontSize: 15 }}
                  className="mb-0 text-danger ms-1"
                >
                  Delete
                </a>
              </div>
            </div>
            <div className="order_box formTogle py-2">
              <a
                href="javascript:void(0)"
                className="m-auto w-100 text-center"
                onclick="$('.formTogle').toggle()"
              >
                + Add New Address
              </a>
            </div>
            <form
              className="order_box form_inputs formTogle"
              style={{ display: "none" }}
            >
              <div className="row gy-3">
                <div className="col-lg-6">
                  <label>Address Type</label>
                  <input type="text" className="form-control" name="" />
                </div>
                <div className="col-lg-6">
                  <label>Alt. Phone Number</label>
                  <input type="text" className="form-control" name="" />
                </div>
                <div className="col-lg-6">
                  <label>State *</label>
                  <input
                    type="text"
                    className="form-control"
                    name=""
                    required=""
                  />
                </div>
                <div className="col-lg-6">
                  <label>City *</label>
                  <input
                    type="text"
                    className="form-control"
                    name=""
                    required=""
                  />
                </div>
                <div className="col-lg-12">
                  <label>Road name, Area, Colony *</label>
                  <input
                    type="text"
                    className="form-control"
                    name=""
                    required=""
                  />
                </div>
                <div className="col-lg-12">
                  <label>Add Nearby shop/Mall/School/Landmark *</label>
                  <input type="text" className="form-control" name="" />
                </div>
                <div className="col-12 text-end">
                  <button
                    type="button"
                    className="btn btn-md btn-dark"
                    onclick="$('.formTogle').toggle()"
                  >
                    Cancel
                  </button>
                  <button type="button" className="btn btn-md btn-primary">
                    Save Changes
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/* cart-area-end */}
</main>

  )
}

export default Address