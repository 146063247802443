import React,{useEffect} from 'react'
import aboutbanner from '../viewer_assets/img/banner/page-banner-1.jpg'
import aboutbanner1 from '../viewer_assets/img/bg/about-bg.png'
import aboutbanner2 from '../viewer_assets/img/about/about-b.png'
import aboutbanner3 from '../viewer_assets/img/about/ab-01.jpg'
import aboutbanner4 from '../viewer_assets/img/about/ab-02.jpg'
import person3 from '../viewer_assets/img/team/person-3.jpg'
import person4 from '../viewer_assets/img/team/person-4.jpg'
import person5 from '../viewer_assets/img/team/person-5.jpg'
import { Link } from 'react-router-dom'

function About() {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        })

    }, [])
  return (
    <div>
         {/* <!-- page-banner-area-start --> */}
    <div class="page-banner-area page-banner-height" style={{ backgroundImage:`url(${aboutbanner})` }}>
        <div class="container">
            <div class="row">
                <div class="col-xl-12">
                    <div class="page-banner-content text-center">
                        <h3>About Us</h3>
                        <p>A wonderful serenity has taken possession of my entire soul, like these <br/> sweet mornings of spring which I enjoy with my whole heart.</p>
                        <div class="page-bottom-btn mt-55">
                            <Link to={"/"} class="st-btn-4">Discover now</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    {/* <!-- page-banner-area-end --> */}

    {/* <!-- about-area-start --> */}
    <div class="about-area pt-80 pb-80" style={{ backgroundImage:`url(${aboutbanner1})` }}>
        <div class="container">
            <div class="row align-items-center">
               <div class="col-xl-6 col-lg-6">
                   <div class="about-content">
                       <span>ABOUT OUR ONLINE STORE</span>
                       <h4>Hello,</h4>
                       <h5 class="banner-t mb-30">With 25+ Years Of Experience</h5>
                       <p class="about-text">Over 25 years Dukamarket helping companies reach their <br/> financial and branding goals.</p>
                       <p>The perfect way to enjoy brewing tea on low hanging fruit to identify. Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis. For me, the most important part of improving at photography.</p>
                   </div>
               </div>
               <div class="col-xl-6 col-lg-6">
                    <div class="about-image w-img">
                        <img src={aboutbanner2} alt="logo"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
    {/* <!-- about-area-end --> */}

    {/* <!-- services-area-start --> */}
    <div class="services-area pt-70 light-bg-s pb-50">
        <div class="container">
            <div class="row">
                <div class="col-xl-12">
                    <div class="abs-section-title text-center">
                        <span>HOW IT WORKS</span>
                        <h4>Complete Customer Ideas</h4>
                        <p>The perfect way to enjoy brewing tea on low hanging fruit to identify. Duis autem vel eum iriure dolor in hendrerit <br/> in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis.</p>
                    </div>
                </div>
            </div>
            <div class="row mt-40">
                <div class="col-xl-4 col-lg-4 col-md-6">
                    <div class="services-item mb-30">
                        <div class="services-icon mb-25">
                            <i class="fa fa-share-square"></i>
                        </div>
                        <h6>Admin Verify Details</h6>
                        <p>Sed ut perspiciatis unde omnis iste natus error sit accusantium doloremque laudantium totam rem aperiam, eaqueipsa quae veritatis.</p>
                        <div class="s-count-number">
                            <span>01</span>
                        </div>
                    </div>
                </div>
                <div class="col-xl-4 col-lg-4 col-md-6">
                    <div class="services-item mb-30">
                        <div class="services-icon mb-25">
                            <i class="fa fa-file"></i>
                        </div>
                        <h6>Send The Solution</h6>
                        <p>Sed ut perspiciatis unde omnis iste natus error sit accusantium doloremque laudantium totam rem aperiam, eaqueipsa quae veritatis.</p>
                        <div class="s-count-number">
                            <span>02</span>
                        </div>
                    </div>
                </div>
                <div class="col-xl-4 col-lg-4 col-md-6">
                    <div class="services-item mb-30">
                        <div class="services-icon mb-25">
                            <i class="fa fa-thumbs-up"></i>
                        </div>
                        <h6>Complete Profile</h6>
                        <p>Sed ut perspiciatis unde omnis iste natus error sit accusantium doloremque laudantium totam rem aperiam, eaqueipsa quae veritatis.</p>
                        <div class="s-count-number">
                            <span>03</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    {/* <!-- services-area-end --> */}

    {/* <!-- technolgy-index-start --> */}
    <div class="technolgy-index mt-70 mb-10">
        <div class="container">
            <div class="row mb-15">
                <div class="col-xl-12">
                    <div class="abs-section-title text-center">
                        <span>TECHNOLOGY INDEX</span>
                        <h4>Let’s Get Creative</h4>
                        <p>The perfect way to enjoy brewing tea on low hanging fruit to identify. Duis autem vel eum iriure dolor in hendrerit <br/> in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis.</p>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-xl-6 col-lg-6">
                    <div class="main-abs mb-30">
                        <div class="ab-counter-image w-img mb-40">
                            <img src={aboutbanner3} alt="logo"/>
                        </div>
                        <div class="row">
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                <div class="ab-counter-item mb-30">
                                    <div class="ab-counter mb-20">
                                        <div class="counter-icon mr-10">
                                            <img src="viewer_assets/img/about/c-icon-01.png" alt=""/>
                                        </div>
                                        <div class="counter_info">
                                            <span class="counter">8262</span>
                                           <p>+</p>
                                       </div>
                                    </div>
                                    <h5>Active Clients</h5>
                                    <p>Sed ut perspiciatis unde omnis iste natus sit accusantium doloremque laudantium totam.</p>
                                </div>
                            </div>
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                <div class="ab-counter-item mb-30">
                                    <div class="ab-counter mb-20">
                                        <div class="counter-icon mr-10">
                                            <img src="viewer_assets/img/about/c-icon-01.png" alt=""/>
                                        </div>
                                        <div class="counter_info">
                                            <span class="counter">4268</span>
                                           <p>+</p>
                                       </div>
                                    </div>
                                    <h5>Projects Done</h5>
                                    <p>Sed ut perspiciatis unde omnis iste natus sit accusantium doloremque laudantium totam.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-6 col-lg-6">
                    <div class="main-abs mb-30">
                        <div class="ab-counter-image w-img mb-40">
                            <img src={aboutbanner4} alt="logo"/>
                        </div>
                        <div class="row">
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                <div class="ab-counter-item mb-30">
                                    <div class="ab-counter mb-20">
                                        <div class="counter-icon mr-10">
                                            <img src="viewer_assets/img/about/c-icon-01.png" alt=""/>
                                        </div>
                                        <div class="counter_info">
                                            <span class="counter">643</span>
                                           <p>+</p>
                                       </div>
                                    </div>
                                    <h5>Team Advisors</h5>
                                    <p>Sed ut perspiciatis unde omnis iste natus sit accusantium doloremque laudantium totam.</p>
                                </div>
                            </div>
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                <div class="ab-counter-item mb-30">
                                    <div class="ab-counter mb-20">
                                        <div class="counter-icon mr-10">
                                            <img src="viewer_assets/img/about/c-icon-01.png" alt=""/>
                                        </div>
                                        <div class="counter_info">
                                            <span class="counter">269</span>
                                           <p>k</p>
                                       </div>
                                    </div>
                                    <h5>Users Online</h5>
                                    <p>Sed ut perspiciatis unde omnis iste natus sit accusantium doloremque laudantium totam.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    {/* <!-- technolgy-index-end --> */}

    {/* <!-- team-area-start --> */}
    <div class="team-area light-bg-s pt-70 pb-40">
        <div class="container">
            <div class="row mb-35">
                <div class="col-xl-12">
                    <div class="abs-section-title text-center">
                        <span>THE TEAM</span>
                        <h4>Meet Our Team</h4>
                        <p>The perfect way to enjoy brewing tea on low hanging fruit to identify. Duis autem vel eum iriure dolor in hendrerit <br/> in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis.</p>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                    <div class="single-team text-center mb-30">
                        <div class="team-image mb-35 w-img">
                            <div class="inner-timg">
                                <a href="about.php">
                                    <img src={person3} alt="logo"/>
                                </a>
                            </div>
                        </div>
                        <div class="tauthor-degination mb-15">
                            <h5><a href="about.php">Iqbal Hossain</a></h5>
                            <span>CEO & Pounder</span>
                        </div>
                        <div class="team-social">
                            <a href="#"><i class="fab fa-facebook-f"></i></a>
                            <a href="#"><i class="fab fa-twitter"></i></a>
                            <a href="#"><i class="fab fa-dribbble"></i></a>
                            <a href="#"><i class="fab fa-youtube"></i></a>
                        </div>
                    </div>
                </div>
                <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                    <div class="single-team text-center mb-30">
                        <div class="team-image mb-35 w-img">
                            <div class="inner-timg">
                                <a href="about.php">
                                    <img src={person4} alt="logo"/>
                                </a>
                            </div>
                        </div>
                        <div class="tauthor-degination mb-15">
                            <h5><a href="about.php">Robin Gomes</a></h5>
                            <span>CEO & Pounder</span>
                        </div>
                        <div class="team-social">
                            <a href="#"><i class="fab fa-facebook-f"></i></a>
                            <a href="#"><i class="fab fa-twitter"></i></a>
                            <a href="#"><i class="fab fa-dribbble"></i></a>
                            <a href="#"><i class="fab fa-youtube"></i></a>
                        </div>
                    </div>
                </div>
                <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                    <div class="single-team text-center mb-30">
                        <div class="team-image mb-35 w-img">
                            <div class="inner-timg">
                                <a href="about.php">
                                    <img src={person5} alt="logo"/>
                                </a>
                            </div>
                        </div>
                        <div class="tauthor-degination mb-15">
                            <h5><a href="about.php">Merry Bob</a></h5>
                            <span>CEO & Pounder</span>
                        </div>
                        <div class="team-social">
                            <a href="#"><i class="fab fa-facebook-f"></i></a>
                            <a href="#"><i class="fab fa-twitter"></i></a>
                            <a href="#"><i class="fab fa-dribbble"></i></a>
                            <a href="#"><i class="fab fa-youtube"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    {/* <!-- team-area-end --> */}
    </div>
  )
}

export default About