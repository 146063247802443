import React, { useState, useEffect } from 'react'
import loginbanner from '../viewer_assets/img/banner/page-banner-6.jpg'
import { Link, NavLink, useNavigate } from 'react-router-dom'
import { useSelector } from "react-redux";
import { remove } from "../utils/grtIdinlocal";
import { useDispatch } from "react-redux";
import { productByRemoveCart } from "../redux/productAction";
import { toastSuccess, toastWarn } from "../utils/notifyCustom"
import { quantityShow, quantityShowData } from "../utils/quantity"
function Cart() {
   const cartData = useSelector((state) => state.cartData);
   const dispatch = useDispatch();
   const navigate = useNavigate();
   const hendleRemoveToCatd = async (id) => {
      const cartIdsArr = remove(id);
      dispatch(productByRemoveCart(cartIdsArr));
      toastSuccess("successfully removed to cart")
   };
   const [dataQ, setdataQ] = useState([])
   const [amount, setAmount] = useState('');
   // const [first, setfirst] = useState(second)
   const increment = (id) => {
      var jsonString = JSON.parse(localStorage.getItem("MKquantity"))
      const updatedArray = jsonString.map(item => {
         if (item.id === id) {
            if (item.quantity === 10) {
               toastWarn("Quantity should not be more than 10 ");
               return item; // Don't update quantity if it's already 1
            } else {
               return { ...item, quantity: item.quantity + 1 }; // Increase quantity by 1
            }
         }
         return item;
      });
      console.log(updatedArray);
      setdataQ(updatedArray)
      quantityShow(updatedArray)
   }

   const decrement = (id) => {
      const jsonString = localStorage.getItem("MKquantity");
      const dataArray = JSON.parse(jsonString);

      const updatedArray = dataArray.map((item) => {
         if (item.id === id) {
            if (item.quantity === 1) {
               const cartIdsArr = remove(item.id);
               dispatch(productByRemoveCart(cartIdsArr));
               toastWarn("Quantity should not be less than one");
               return item; // Don't update quantity if it's already 1
            } else {
               return { ...item, quantity: item.quantity - 1 }; // Decrease quantity by 1
            }
         }
         return item;
      });

      console.log(updatedArray);
      setdataQ(updatedArray);
      quantityShow(updatedArray);
   };
   console.log(quantityShowData())
   var quantitydata = quantityShowData()

   console.log(dataQ)

   const matchingItems = cartData.filter(item =>
      quantitydata.some(quantityItem => quantityItem.id === item.id)
   );

   useEffect(() => {
      if (quantitydata && quantitydata.length > 0) {
         let totalAmount =
            matchingItems.length &&
            matchingItems.reduce((total, item) => {
               const quantityItem = quantitydata.find(qItem => qItem.id === item.id);
               return total + item.sell_price * quantityItem.quantity;
            }, 0);

         totalAmount = totalAmount.toFixed(2);
         setAmount(totalAmount);
      } else {
         setAmount(0);
      }
   }, [quantitydata, setAmount]);

   let Subtotal =
      cartData.length &&
      cartData.map((item) => +item.sell_price).reduce((prev, next) => prev + next);

   Subtotal = Subtotal.toFixed(2);

   const checkoutpage = (e) => {
      e.preventDefault();
      console.log("iiii", quantitydata && quantitydata.length > 0)
      if (quantitydata && quantitydata.length > 0) {
         navigate('/checkout')
      } else {
         toastWarn("your cart is empty")
      }
   }

   return (
      <div>
         {/* <!-- page-banner-area-start --> */}
         {/*<div className="page-banner-area page-banner-height-2" style={{ backgroundImage: `url(${loginbanner})` }}>
            <div className="container">
               <div className="row">
                  <div className="col-xl-12">
                     <div className="page-banner-content text-center">
                        <h4 className="breadcrumb-title">Your Cart</h4>
                        <div className="breadcrumb-two">
                           <nav>
                              <nav className="breadcrumb-trail breadcrumbs">
                                 <ul className="breadcrumb-menu">
                                    <li className="breadcrumb-trail">
                                       <Link to={"/"}><span>Home</span></Link>
                                    </li>
                                    <li className="trail-item">
                                       <span>Cart</span>
                                    </li>
                                 </ul>
                              </nav>
                           </nav>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>*/}
         {/* <!-- page-banner-area-end --> */}

         {/* <!-- cart-area-start --> */}
         {/* <section className="cart-area pt-120 pb-120">
            <div className="container">
               <div className="row">
                  <div className="col-12">
                     <form action="#">
                        <div className="table-content table-responsive">
                           <table className="table">
                              <thead>
                                 <tr>
                                    <th className="product-thumbnail">Images</th>
                                    <th className="cart-product-name">Product</th>
                                    <th className="product-price">Unit Price</th>
                                    <th className="product-quantity">Quantity</th>
                                    <th className="product-subtotal">Total</th>
                                    <th className="product-remove">Remove</th>
                                 </tr>
                              </thead>
                              <tbody>
                                 {cartData && cartData.map((item, index) =>
                                    <tr key={index}>
                                       <td className="product-thumbnail"><NavLink to={`/product_details/${encodeURIComponent(item.title)}`}><img src={`${process.env.REACT_APP_API_PRODUCTS_IMG}/${item.main_img}`} alt="logo" /></NavLink></td>
                                       <td className="product-name"><NavLink to={`/product_details/${encodeURIComponent(item.title)}`}>{item.name}</NavLink></td>
                                       <td className="product-price"><span className="amount">₹{item.sell_price}</span></td>
                                       <td className="product-quantity">
                                          <div className="cart-plus-minus">
                                             {quantitydata && quantitydata[index] && (
                                                <h6>{quantitydata[index].quantity}</h6>
                                             )}
                                             <div className="dec qtybutton" onClick={() => decrement(item.id)}>-</div><div className="inc qtybutton" onClick={() => increment(item.id)}>+</div>
                                          </div>
                                       </td>
                                       <td className="product-subtotal"><span className="amount">₹{(item.sell_price * quantitydata[index].quantity).toFixed(2)}</span></td>
                                       <td className="product-remove" style={{ cursor: 'pointer' }}><a onClick={() => hendleRemoveToCatd(item.id)}><i className="fa fa-times"></i></a></td>
                                    </tr>
                                 )}
                              </tbody>
                           </table>
                        </div>
                    
                        <div className="row justify-content-end">
                           <div className="col-md-5">
                              <div className="cart-page-total">
                                 <h2>Cart totals</h2>
                                 <ul className="mb-20">
                                    <li>Subtotal <span>₹ {Subtotal}</span></li>
                                    <li>Total <span>₹ {amount}</span></li>
                                 </ul>
                                 
                                 <button onClick={checkoutpage} className="tp-btn-h1"> Proceed to checkout </button>

                              </div>
                           </div>
                        </div>
                     </form>
                  </div>
               </div>
            </div>
         </section> */}
         <section class="py-lg-5 py-md-5 py-sm-3 py-2">
            <div class="container">
               <div class="row">
                  <div class="col-xl-8 col-lg-7">
                     <h5 class="mb-3 mt-lg-0 mt-md-0 mt-2">Shopping Cart</h5>
                     <div class="item_box">
                        {cartData && cartData.map((item, index) =>
                           <div class="item_list" key={index}>
                              <Link to={`/product_details/${encodeURIComponent(item.title)}`} class="item_img"><img src={`${process.env.REACT_APP_API_PRODUCTS_IMG}/${item.main_img}`} /></Link>
                              <div class="item_info">
                                 <div class="item_fill">
                                    <h6>{item.name}</h6>
                                    {/* <h6><small>COLOR: Red, Size: XL</small></h6> */}
                                 </div>
                                 <div class="price_info">
                                    <h6 class="mb-0">₹{item.sell_price}</h6>
                                    <div class="qnty_input d-flex">
                                       <span class="fs_24" onClick={() => decrement(item.id)}>-</span>
                                       {quantitydata && quantitydata[index] && (
                                          <span className='fs_24'>{quantitydata[index].quantity}</span>
                                       )}
                                       <span onClick={() => increment(item.id)}>+</span>
                                    </div>
                                    <h6 class="mb-0">₹{(item.sell_price * quantitydata[index].quantity).toFixed(2)}</h6>
                                    <a href="javascript:void(0)"><i class="mdi mdi-trash-can-outline p-2 fs_18"></i></a>
                                 </div>
                              </div>
                           </div>
                        )}
                     </div>
                  </div>

                  <div className="col-xl-4 col-lg-5">
                     <div style={{position: 'sticky', top: '200px'}}>
                        <h5 class="mb-3 mt-lg-0 mt-md-0 mt-2">Cart Total</h5>
                        <div className="cart-page-total pt-0">
                           <ul className="mb-1">
                              <li>Subtotal <span>₹ {Subtotal}</span></li>
                              <li>Total <span>₹ {amount}</span></li>
                           </ul>

                           <button onClick={checkoutpage} className="tp-btn-h1 w-100"> Proceed to checkout </button>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>
         {/* <!-- cart-area-end --> */}
      </div>
   )
}

export default Cart