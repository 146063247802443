import logo from "../viewer_assets/img/logo/logo.png"
export function rzp(totalAmount) {
    console.log(totalAmount,"==============<<<<<<<<<<<<<<<>>>>>>>>>>")
    var options = {
        "key": "rzp_test_upCuUurhM3I9JM", // Enter the Key ID generated from the Dashboard
        "amount": (totalAmount * 100), // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
        "currency": "INR",
        "name": "MK-Ecommerce", //your business name
        "description": "Test Transaction",
        "image": logo,
        // "order_id": "order_9A33XWu170gUtm", //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
        "callback_url": "https://eneqd3r9zrjok.x.pipedream.net/",
        "prefill": { //We recommend using the prefill parameter to auto-fill customer's contact information especially their phone number
            "name": "Gaurav Kumar", //your customer's name
            "email": "gaurav.kumar@example.com",
            "contact": "9088705526" //Provide the customer's phone number for better conversion rates 
        },
        "notes": {
            "address": "Razorpay Corporate Office"
        },
        "theme": {
            "color": "#263C97"
        }
    };
    var rzp1 = new  window.Razorpay(options);
    return rzp1
}