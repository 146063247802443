import React, { useState, useEffect, useRef } from "react";
import faqbanner from '../../../viewer_assets/img/banner/page-banner-3.jpg'
import "../footer.css"
import axios from "axios";
import { useQuery } from "react-query";

const fetchPrivacypolicy = async () => {
    const formData = new FormData();
    formData.append("page_slug", "terms-conditions");
    const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_API_GET_PAGE}`,
        formData
    );
    return response.data;
};

const TermsConditions = () => {

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        })

    }, [])

    const fetchPrivacypolicyQuery = useQuery(["privacy"], () =>
        fetchPrivacypolicy()
    );
    console.log(fetchPrivacypolicyQuery.data)
    return (
        <div>
            {/* <!-- page-banner-area-start --> */}
            <div class="page-banner-area page-banner-height" style={{ backgroundImage: `url(${faqbanner})` }}>
                <div class="container">
                    <div class="row">
                        <div class="col-xl-12">
                            <div class="page-banner-content text-center">
                                <h4 class="breadcrumb-title">Privacy policies</h4>
                                <div class="breadcrumb-two">
                                    <nav>
                                        <nav class="breadcrumb-trail breadcrumbs">
                                            <ul class="breadcrumb-menu">
                                                <li class="breadcrumb-trail">
                                                    <a href="index.php"><span>Home</span></a>
                                                </li>
                                                <li class="trail-item">
                                                    <span>Privacy policies</span>
                                                </li>
                                            </ul>
                                        </nav>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- page-banner-area-end --> */}

            {/* <!-- faq-area-start --> */}
            <div className="bg-gray-100">

                <div className="container mx-auto px-4">
                    <div className="gradient-form bg-gray-100 p-5">

                        <p
                           
                            className="mt-4"
                            dangerouslySetInnerHTML={{ __html: fetchPrivacypolicyQuery.data && fetchPrivacypolicyQuery.data.content }}
                        />

                    </div>
                </div>
            </div>
            {/* <!-- faq-area-end --> */}
        </div>
    )
}

export default TermsConditions
