export function getOffer(price, sellPrice) {
  try {
    const offer = getPriceFormat(((price - sellPrice) * 100) / sellPrice).toFixed()
    // console.log('Offer:', offer);
    return offer;
  } catch (error) {
    console.error('Error in getOffer:', error);
    return 0; // Default value or error handling
  }
}
function getPriceFormat(price) {
  if (price === '') {
    return 0;
  } else {
    return parseFloat(price).toFixed(2) * 1;
  }
}