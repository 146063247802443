import { useQuery } from "react-query";
import axios from "axios";
import { Link,useNavigate } from "react-router-dom";
import React, { useState, useEffect, useRef } from "react";
import LoadingBar from "react-top-loading-bar";
import { getOffer } from "../../utils/getOffer";
import { useDispatch, useSelector } from "react-redux";
import { productByAddCart } from "../../redux/productAction";
import { get } from "../../utils/grtIdinlocal";
import { toastSuccess } from "../../utils/notifyCustom"
const Featured_start = () => {
  const dispatch = useDispatch();
  const loadingBar = useRef(null);
  const cartData = useSelector((state) => state.cartData);
  const navigate = useNavigate();
  const fetchFeaturedProducts = async () => {
    const formData = new FormData();
    formData.append("q_type", "feature");

    const response = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_API_LOAD_SELECTED_PRODUCTS}`,
      formData
    );
    return response.data.product;
  };

  const productFeaturedQuery = useQuery(
    "featuredproducts",
    fetchFeaturedProducts,{
      refetchOnMount: false,
        refetchOnWindowFocus: false

    }
  );

  const startLoading = () => {
    loadingBar.current.continuousStart();
  };

  const finishLoading = () => {
    loadingBar.current.complete();
  };

  useEffect(() => {
    if (productFeaturedQuery.isLoading) {
      startLoading();
    } else {
      finishLoading();
    }
  }, [productFeaturedQuery.isLoading]);

  if (productFeaturedQuery.isLoading) {
    return (
      <div>
        <LoadingBar color="#f11946" ref={loadingBar} />
        {/* <h2>Loading...</h2> */}
      </div>
    );
  }
  console.log("uuuuuddd", productFeaturedQuery.data);

  const hendleAddToCatd = async (id) => {
    if (cartData.find((cartItem) => +cartItem.id == id)) {
      navigate('/cart')
    } else {
    const cartIdsArr = get(id);
    console.log(cartIdsArr);
    dispatch(productByAddCart(cartIdsArr));
    toastSuccess("Successfully Add to cart")
    }
  };

  return (
    <div>
      <LoadingBar color="#f11946" ref={loadingBar} />
      <section className="featured light-bg-s pt-50 pb-40">
        <div className="container 0">
          <div className="row">
            <div className="col-xl-12">
              <div className="section__head d-flex justify-content-between mb-30">
                <div className="section__title section__title-2">
                  <h5 className="st-titile">Top Featured Products</h5>
                </div>
                <div className="button-wrap button-wrap-2">
                  <a href="product.html">
                    See All Product <i className="fa fa-chevron-right"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-6 col-lg-12">
              {productFeaturedQuery.data &&
                productFeaturedQuery.data.map((item, index) => {
                  if (index === 0) {
                    return (
                      <div
                        key={index}
                        className="single-features-item single-features-item-d b-radius-2 mb-20"
                      >
                        <div className="row  g-0 align-items-center">
                          <div className="col-md-6">
                            <div className="features-thum">
                              <div className="features-product-image w-img me-3">
                                <Link
                                  to={`/product_details/${encodeURIComponent(
                                    item.title
                                  )}`}
                                >
                                  <img
                                    src={`${process.env.REACT_APP_API_PRODUCTS_IMG}/${item.main_img}`}
                                    alt="product"
                                  />
                                </Link>
                              </div>
                              <div className="product__offer">
                                <span
                                  className="discount"
                                  style={{
                                    display:
                                      getOffer(item.price, item.sell_price) ==
                                        "0"
                                        ? "none"
                                        : "inline",
                                  }}
                                >
                                  {getOffer(item.price, item.sell_price)}%
                                </span>
                              </div>
                              <div className="product-action product-action-2">
                                <Link
                                  to={`/product_details/${encodeURIComponent(
                                    item.title
                                  )}`}
                                >
                                  <a
                                    className="icon-box icon-box-1"
                                    data-bs-toggle="modal"
                                    data-bs-target="#productModalId"
                                  >
                                    <i className="fa fa-eye"></i>
                                    <i className="fa fa-eye"></i>
                                  </a>
                                </Link>
                                <a className="icon-box icon-box-1">
                                  <i className="fa fa-layer-group"></i>
                                  <i className="fa fa-layer-group"></i>
                                </a>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="product__content product__content-d product__content-d-2">
                              <Link
                                to={`/product_details/${encodeURIComponent(
                                  item.title
                                )}`}
                              >
                                <h6>
                                  <a className="a2">{item.name}</a>
                                </h6>
                              </Link>
                              <div className="rating mb-5">
                                <ul className="rating-d">
                                  <li>
                                    <a href="#">
                                      <i className="fa fa-star"></i>
                                    </a>
                                  </li>
                                  <li>
                                    <a href="#">
                                      <i className="fa fa-star"></i>
                                    </a>
                                  </li>
                                  <li>
                                    <a href="#">
                                      <i className="fa fa-star"></i>
                                    </a>
                                  </li>
                                  <li>
                                    <a href="#">
                                      <i className="fa fa-star"></i>
                                    </a>
                                  </li>
                                  <li>
                                    <a href="#">
                                      <i className="fa fa-star"></i>
                                    </a>
                                  </li>
                                </ul>
                                {/* <span>(01 review)</span> */}
                              </div>
                              <div className="price d-price mb-10">
                                <span>
                                  ₹{item.sell_price} <del>₹{item.price}</del>
                                </span>
                              </div>
                              <div className="features-des mb-25">
                                <ul>
                                  <li>
                                    <a>
                                      <i className="fas fa-circle"></i> Bass and
                                      Stereo Sound.
                                    </a>
                                  </li>
                                  <li>
                                    <a>
                                      <i className="fas fa-circle"></i> Display
                                      with 3088 x 1440 pixels resolution.
                                    </a>
                                  </li>
                                  <li>
                                    <a>
                                      <i className="fas fa-circle"></i> Memory,
                                      Storage &amp; SIM: 12GB RAM, 256GB.
                                    </a>
                                  </li>
                                  <li>
                                    <a>
                                      <i className="fas fa-circle"></i> Androi
                                      v10.0 Operating system.
                                    </a>
                                  </li>
                                </ul>
                              </div>
                              <div className="cart-option">
                                <button
                                  onClick={() => hendleAddToCatd(item.id)}
                                  className={
                                    `w-100 mr-10  ${
                                      cartData.find((cartItem) => +cartItem.id == +item.id)
                                        ? "cart-btn-3"
                                        : "cart-btn"
                                    }`
                                  }
                                >
                                     {cartData.find(
                                  (cartItem) => +cartItem.id == +item.id
                                )
                                  ? "view cart"
                                  : "Add to Cart"}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  }
                  return null;
                })}
            </div>

            <div className="col-xl-6 col-lg-12">
              <div className="row">
                {productFeaturedQuery.data &&
                  productFeaturedQuery.data.map((item, index) => {
                    // Skip the first item (index 0)
                    if (index > 0) {
                      return (
                        <div key={index} className="col-md-6">
                          <div className="single-features-item b-radius-2 mb-20">
                            <div className="row  g-0 align-items-center">
                              <div className="col-6">
                                <div className="features-thum">
                                  <div className="features-product-image w-img me-3">
                                    <Link
                                      to={`/product_details/${encodeURIComponent(
                                        item.title
                                      )}`}
                                    >
                                      <img
                                        src={`${process.env.REACT_APP_API_PRODUCTS_IMG}/${item.main_img}`}
                                        alt="product"
                                      />
                                    </Link>
                                  </div>
                                  <div className="product__offer">
                                    <span
                                      className="discount"
                                      style={{
                                        display:
                                          getOffer(
                                            item.price,
                                            item.sell_price
                                          ) == "0"
                                            ? "none"
                                            : "inline",
                                      }}
                                    >
                                      {getOffer(item.price, item.sell_price)}%
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="col-6">
                                <div className="product__content product__content-d product__content-d-2">
                                  <Link
                                    to={`/product_details/${encodeURIComponent(
                                      item.title
                                    )}`}
                                  >
                                    <h6>
                                      <a className="a2">{item.name}</a>
                                    </h6>
                                  </Link>
                                  <div className="rating mb-5">
                                    <ul>
                                      <li>
                                        <a href="#">
                                          <i className="fa fa-star"></i>
                                        </a>
                                      </li>
                                      <li>
                                        <a href="#">
                                          <i className="fa fa-star"></i>
                                        </a>
                                      </li>
                                      <li>
                                        <a href="#">
                                          <i className="fa fa-star"></i>
                                        </a>
                                      </li>
                                      <li>
                                        <a href="#">
                                          <i className="fa fa-star"></i>
                                        </a>
                                      </li>
                                      <li>
                                        <a href="#">
                                          <i className="fa fa-star"></i>
                                        </a>
                                      </li>
                                    </ul>
                                    {/* <span>(01 review)</span> */}
                                  </div>
                                  <div className="price d-price">
                                    <span>
                                      ₹{item.sell_price}{" "}
                                      <del>₹{item.price}</del>
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    }
                    return null; // Return null for the first item (index 0)
                  })}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Featured_start;
