import React, { useState } from 'react'
import loginbanner from '../viewer_assets/img/banner/page-banner-4.jpg'
import { Link,useNavigate } from 'react-router-dom'
import { toastSuccess } from "../utils/notifyCustom"
import axios from "axios";
import { AiFillEye } from 'react-icons/ai'
import { AiFillEyeInvisible } from "react-icons/ai"
function Login() {
    const navigate = useNavigate();
    const [email, setemail] = useState();
    const [password, setpassword] = useState();

    const [changePassword, setChangePassword] = useState(true);
    const changeIcon = changePassword === true ? false : true;

    const handleLoginButtonClick = async (e) => {
        e.preventDefault();
        console.log(email, password);
        try {
            const formData = new FormData();
            formData.append("username", email);
            formData.append("password", password);

            const response = await axios.post(
                `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_API_LOGIN}`,
                formData
            );

            console.log(response.data.message);
            if (response) {
                localStorage.setItem("MKapi_key", JSON.stringify(response.data.api_key));
                localStorage.setItem("MKuserId", JSON.stringify(response.data.id));
                navigate('/')
                toastSuccess(response.data.message)
            }
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <div>
            {/* <!-- page-banner-area-start --> */}
            <div className="page-banner-area page-banner-height-2" style={{ backgroundImage: `url(${loginbanner})` }}>
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="page-banner-content text-center">
                                <h4 className="breadcrumb-title">Login</h4>
                                <div className="breadcrumb-two">
                                    <nav>
                                        <nav className="breadcrumb-trail breadcrumbs">
                                            <ul className="breadcrumb-menu">
                                                <li className="breadcrumb-trail">
                                                    <Link to={"/"}><span>Home</span></Link>
                                                </li>
                                                <li className="trail-item">
                                                    <span>Login</span>
                                                </li>
                                            </ul>
                                        </nav>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- page-banner-area-end --> */}

            {/* <!-- account-area-start --> */}
            <div className="account-area mt-70 mb-70">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-5">
                            <div className="basic-login mb-50">
                                <h5>Login</h5>
                                <form action="#">
                                    <label for="name">Username or email address  <span>*</span></label>
                                    <input id="name" type="email" placeholder="Enter Username" onChange={(e) => setemail(e.target.value)} />
                                    <div style={{ position: 'relative' }}>
                                        <label htmlFor="pass">Password <span>*</span></label>
                                        <input
                                            id="pass"
                                            type={changePassword ? "password" : "text"}
                                            placeholder="Enter password..."
                                            onChange={(e) => setpassword(e.target.value)}
                                            style={{ paddingRight: '30px' }} // Add padding to accommodate the toggle button
                                        />
                                        <span
                                            onClick={() => {
                                                setChangePassword(!changePassword); // Toggle the state directly
                                            }}
                                            style={{
                                                position: 'absolute',
                                                right: '5px', // Adjusted for better alignment
                                                top: '55%', // Adjusted for vertical centering
                                                transform: 'translateY(-50%)', // Center vertically
                                                cursor: 'pointer',
                                            }}
                                        >
                                            {changePassword ? <AiFillEye size={20} /> : <AiFillEyeInvisible size={20} />}
                                        </span>
                                    </div>

                                    <div className="login-action mb-10 fix">
                                        <span className="log-rem f-left">
                                            <input id="remember" type="checkbox" />
                                            <label for="remember">Remember me</label>
                                        </span>
                                        <span className="forgot-login f-right">
                                            <a href="#">Lost your password?</a>
                                        </span>
                                    </div>
                                    <button className="tp-in-btn w-100" onClick={handleLoginButtonClick}>log in</button>
                                    {/* <p className="mt-3 text-center">Don't have an account? <a className="text-primary" href="register.php">Create Now</a></p> */}
                                    <p className="mt-3 text-center">Don't have an account? <Link to={'/register'} className="text-primary">Create Now</Link> </p>
                                </form>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- account-area-end --> */}
        </div>
    )
}

export default Login