import React, { useState, useEffect, useRef } from "react";
import blogbanner from '../viewer_assets/img/banner/page-banner-4.jpg'
import blogbanner3 from '../viewer_assets/img/blog/news-author.jpg'
import blogbanner4 from '../viewer_assets/img/blog/p-author-1.jpg'
import blogbanner5 from '../viewer_assets/img/blog/p-author-2.jpg'
import blogbanner6 from '../viewer_assets/img/blog/p-author-3.jpg'
import blogbanner11 from '../viewer_assets/img/blog/sponsor-3.jpg'
import { useParams, useNavigate, Link } from "react-router-dom"
import axios from "axios";
import { useQuery } from "react-query";
import LoadingBar from "react-top-loading-bar";



function Blog_details() {
   useEffect(() => {
      window.scrollTo({
         top: 0,
         left: 0,
         behavior: "smooth"
      })

   }, [])
   const { blogId } = useParams();
   const loadingBar = useRef(null);

   const fetchBlogDetails = async (blogid) => {
      console.log(blogId)
      const formData = new FormData();
      formData.append("blog_id", blogid);
      const response = await axios.post(
         `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_API_GET_BLOG_DETAILS}`,
         formData
      );
      return response.data;
   };
   const blogDetailsQuery = useQuery(["blogDetails", blogId], () =>
      fetchBlogDetails(blogId)
   );


   const [blogCatId, setBlogCatId] = useState();
   useEffect(() => {
      if (blogDetailsQuery.data) {
         setBlogCatId(blogDetailsQuery.data.blog_cat_id);
      }
   }, [blogDetailsQuery.data]);


   const fetchRelatedBlog = async (blogId, blogCatId) => {
      console.log(blogId, blogCatId, "================++++++++");
      const formData = new FormData();
      formData.append("blog_id", blogId);
      formData.append("blog_cat_id", blogCatId);
      formData.append("limit", "3");
      const response = await axios.post(
         `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_API_GET_RELATED_BLOG}`,
         formData
      );
      return response.data;
   };
   console.log(blogCatId, "================++++++++");
   const relatedBlogQuery = useQuery(
      ["relatedBlog", blogId, blogCatId],
      () => fetchRelatedBlog(blogId, blogCatId),
      {
         enabled: blogCatId !== undefined && blogCatId !== null,
      }
   );

   const fetchRecentBlog = async () => {
      const formData = new FormData();
      formData.append("limit", "3");
      const response = await axios.post(
         `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_API_GET_RECENT_BLOG}`,
         formData
      );
      return response.data;
   };

   const recentBlogQuery = useQuery(["recentBlog"], () =>
      fetchRecentBlog()
   );


   const startLoading = () => {
      loadingBar.current.continuousStart();
   };

   const finishLoading = () => {
      loadingBar.current.complete();
   };

   useEffect(() => {
      if (blogDetailsQuery.isLoading || relatedBlogQuery.isLoading || recentBlogQuery.isLoading) {
         startLoading();
      } else {
         finishLoading();
      }
   }, [blogDetailsQuery.isLoading, relatedBlogQuery.isLoading, recentBlogQuery.isLoading]);


   if ((blogDetailsQuery.error || relatedBlogQuery.error || recentBlogQuery.error) &&
      (!blogDetailsQuery.isLoading && !relatedBlogQuery.isLoading && !recentBlogQuery.isLoading)) {
      const errorMessage = blogDetailsQuery.error
         ? blogDetailsQuery.error.message
         : relatedBlogQuery.error
            ? relatedBlogQuery.error.message
            : recentBlogQuery.error
               ? recentBlogQuery.error.message
               : "Unknown error occurred"; // Provide a default message if all error objects are falsy

      return <h2>Error: {errorMessage}</h2>;
   }




   if (blogDetailsQuery.isLoading || relatedBlogQuery.isLoading || recentBlogQuery.isLoading) {
      return (
         <div>
            <LoadingBar color="#f11946" ref={loadingBar} />
            {/* <h2>Loading...</h2> */}
         </div>
      );
   }

   console.log(blogDetailsQuery.data, "=============>>", relatedBlogQuery.data)

   return (
      <div>
         <LoadingBar color="#f11946" ref={loadingBar} />
         {/* <!-- page-banner-area-start --> */}
         <div className="page-banner-area page-banner-height-2" style={{ backgroundImage: `url(${blogbanner})` }}>
            <div className="container">
               <div className="row">
                  <div className="col-xl-12">
                     <div className="page-banner-content text-center">
                        <h4 className="breadcrumb-title">Blog Details</h4>
                        <div className="breadcrumb-two">
                           <nav>
                              <nav className="breadcrumb-trail breadcrumbs">
                                 <ul className="breadcrumb-menu">
                                    <li className="breadcrumb-trail">
                                       <Link to={"/"}><span>Home</span></Link>
                                    </li>
                                    <li className="trail-item">
                                       <span>Blog Details</span>
                                    </li>
                                 </ul>
                              </nav>
                           </nav>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         {/* <!-- page-banner-area-end --> */}

         {/* <!-- news-detalis-area-start --> */}
         <div className="news-detalis-area mt-120 mb-70">
            <div className="container">
               <div className="row">
                  <div className="col-xl-8 col-lg-8">
                     <div className="news-detalis-content mb-50">
                        <ul className="blog-meta mb-20">
                           <li><a href="blog-details.php"><i className="fa fa-eye"></i>100 Views</a></li>
                           <li><a href="blog-details.php"><i className="fa fa-comments"></i> 30 Comments</a></li>
                           <li><a href="blog-details.php"><i className="fa fa-calendar-alt"></i> {blogDetailsQuery.data?.publish_date}</a></li>
                        </ul>
                        {/* <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explic abo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur mag ni dolores eos qui ratione voluptatem sequi nesciunt.</p> */}
                        <div className="news-thumb mt-40">
                           {blogDetailsQuery.data && blogDetailsQuery.data.blog_img && (
                              <img src={`${process.env.REACT_APP_API_BLOG_IMG}/${blogDetailsQuery.data.blog_img}`} alt="Blog Image" />
                           )}

                        </div>
                        <h4 className="news-title mt-60">{blogDetailsQuery.data?.blog_title}</h4>
                        <p className="mt-25 mb-50" dangerouslySetInnerHTML={{ __html: blogDetailsQuery.data?.blog_details }}></p>
                        {/* <h4 className="news-title news-title-2 pt-55">Laptops Become More Common in Everyday Life</h4>
                        <div className="blog-inner mt-30">
                           <img src={blogbanner2} alt="" className="img-fluid" />
                           <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate Lorem ipsum dolor sit amet, co nsectetur adipisicing elit, sed do eiusmod</p>
                        </div> */}
                        {/* <div className="news-quote-area mt-55 text-center">
                           <i className="fas fa-quote-left"></i>
                           <h5 className="news-quote-title mt-25">Choices to take a holiday and travelling out in this pandemic situation are limited. Why not take a staycation.</h5>
                           <h6 className="founder-name mt-25">Rosalina D. William</h6>
                           <span className="founder-title">Founder</span>
                        </div> */}
                        {/* <p className="mt-55">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Sed ut perspiciatis unde omnis iste natus error sit voluptatem. Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p> */}
                        {/* <div className="news-info d-sm-flex align-items-center justify-content-between mt-50 mb-50">
                           <div className="news-tag">
                              <h6 className="tag-title mb-25">Releted Tags</h6>
                              <a href="#"> Popular</a>
                              <a href="#">Desgin</a>
                              <a href="#">UX</a>
                           </div>
                           <div className="news-share">
                              <h6 className="tag-title mb-25">Social Share</h6>
                              <a href="#"><i className="fab fa-facebook-f"></i></a>
                              <a href="#"><i className="fab fa-twitter"></i></a>
                              <a href="#"><i className="fab fa-typo3"></i></a>
                              <a href="#"><i className="fab fa-tumblr"></i></a>
                              <a href="#"><i className="fa fa-share-alt"></i></a>
                           </div>
                        </div> */}
                        {/* <div className="news-navigation pt-50 pb-40">
                           <div className="changes-info">
                              <span><a href="blog-details.php">Prev Post</a></span>
                              <h6 className="changes-info-title"><a href="blog-details.php">Tips On Minimalist</a></h6>
                           </div>
                           <div className="changes-info text-md-right">
                              <span><a href="blog-details.php">Next Post</a></span>
                              <h6 className="changes-info-title"><a href="blog-details.php">Less Is More</a></h6>
                           </div>
                        </div> */}
                        {/* <div className="news-author mt-60">
                           <img src={blogbanner3} alt="" className="img-fluid" />
                           <div className="news-author-info">
                              <span>Written by</span>
                              <h6 className="author-title">Rosalina D. William</h6>
                              <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation is enougn for today. quis nostrud exercitation is enougn for.</p>
                           </div>
                        </div> */}
                        {/* <div className="post-comments mt-60">
                           <h6 className="post-comment-title mb-40">03 Comments</h6>
                           <div className="latest-comments">
                              <ul>
                                 <li>
                                    <div className="comments-box">
                                       <div className="comments-avatar">
                                          <img src={blogbanner4} alt="" />
                                       </div>
                                       <div className="comments-text">
                                          <div className="avatar">
                                             <h6 className="avatar-name">Rosalina Kelian</h6>
                                             <a href="#" className="comment-reply"><i className="fa fa-share"></i>Reply</a>
                                          </div>
                                          <span className="post-meta"><i className="fa fa-calendar-alt"></i> October 26, 2020</span>
                                          <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                                       </div>
                                    </div>
                                 </li>
                                 <li className="children">
                                    <div className="comments-box">
                                       <div className="comments-avatar">
                                          <img src={blogbanner5} alt="" />
                                       </div>
                                       <div className="comments-text">
                                          <div className="avatar">
                                             <h6 className="avatar-name">Iqbal Hossain</h6>
                                             <a href="#" className="comment-reply"><i className="fa fa-share"></i>Reply</a>
                                          </div>
                                          <span className="post-meta"><i className="fa fa-calendar-alt"></i> October 26, 2020</span>
                                          <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incid idunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco..</p>
                                       </div>
                                    </div>
                                 </li>
                                 <li>
                                    <div className="comments-box">
                                       <div className="comments-avatar">
                                          <img src={blogbanner6} alt="" />
                                       </div>
                                       <div className="comments-text">
                                          <div className="avatar">
                                             <h6 className="avatar-name">Arista Williamson</h6>
                                             <a href="#" className="comment-reply"><i className="fa fa-share"></i>Reply</a>
                                          </div>
                                          <span className="post-meta"><i className="fa fa-calendar-alt"></i> October 26, 2020</span>
                                          <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolo re magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                                       </div>
                                    </div>
                                 </li>
                              </ul>
                           </div>
                        </div> */}
                        {/* <div className="post-comment-form mt-20">
                           <h4 className="post-comment-form-title mb-40">Post Comment</h4>
                           <form action="#">
                              <div className="input-field">
                                 <i className="fa fa-pencil-alt"></i>
                                 <textarea name="comment" id="comment" placeholder="Type your comments...."></textarea>
                              </div>
                              <div className="input-field">
                                 <i className="fa fa-user"></i>
                                 <input type="text" placeholder="Type your name...." />
                              </div>
                              <div className="input-field">
                                 <i className="fa fa-envelope"></i>
                                 <input type="text" placeholder="Type your email...." />
                              </div>
                              <div className="input-field">
                                 <i className="fa fa-globe"></i>
                                 <input type="text" placeholder="Type your website...." />
                              </div>
                              <button className="post-comment shutter-btn"><i className="fa fa-comments"></i>Post
                                 Comment</button>
                           </form>
                        </div> */}
                     </div>
                  </div>
                  <div className="col-xl-4 col-lg-4">
                     <div className="news-sidebar pl-10">
                        <div className="row">
                           <div className="col-lg-12 col-md-12">
                              <div className="widget">
                                 <h6 className="sidebar-title"> Search Here</h6>
                                 <div className="n-sidebar-search">
                                    <input type="text" placeholder="Search your keyword..." />
                                    <a href="#"><i className="fa fa-search"></i></a>
                                 </div>
                              </div>
                           </div>
                           <div className="col-lg-12 col-md-12">
                              <div className="widget">
                                 <h6 className="sidebar-title">Recent blog</h6>
                                 <div className="n-sidebar-feed">
                                    <ul>
                                       {recentBlogQuery.data &&
                                          recentBlogQuery.data.map((item, index) => (
                                             <li key={index}>
                                                <div className="feed-number">
                                                   <Link to={`/blog_details/${item.id}`}><img src={`${process.env.REACT_APP_API_BLOG_IMG}/${item.blog_img}`} alt="" /></Link>
                                                </div>
                                                <div className="feed-content">
                                                   <h6><Link to={`/blog_details/${item.id}`}>{item.blog_title}</Link></h6>
                                                   <span className="feed-date">
                                                      <i className="fa fa-calendar-alt"></i> {item.publish_date}
                                                   </span>
                                                </div>
                                             </li>
                                          ))}
                                    </ul>
                                 </div>
                              </div>
                           </div>
                           {/* <div className="col-lg-12 col-md-6">
                              <div className="widget">
                                 <h6 className="sidebar-title">Categories</h6>
                                 <ul className="n-sidebar-categories">
                                    <li>
                                       <a href="blog-details.php">
                                          <div className="single-category p-relative mb-10">
                                             {blogDetailsQuery.data?.category_name}
                                             <span className="category-number">26</span>
                                          </div>
                                       </a>
                                    </li>
                                    <li>
                                       <a href="blog-details.php">
                                          <div className="single-category p-relative mb-10">
                                             consultant
                                             <span className="category-number">30</span>
                                          </div>
                                       </a>
                                    </li>
                                    <li>
                                       <a href="blog-details.php">
                                          <div className="single-category p-relative mb-10">
                                             Creative
                                             <span className="category-number">71</span>
                                          </div>
                                       </a>
                                    </li>
                                    <li>
                                       <a href="blog-details.php">
                                          <div className="single-category p-relative mb-10">
                                             UI/UX
                                             <span className="category-number">56</span>
                                          </div>
                                       </a>
                                    </li>
                                    <li>
                                       <a href="blog-details.php">
                                          <div className="single-category p-relative">
                                             Texhnology
                                             <span className="category-number">60</span>
                                          </div>
                                       </a>
                                    </li>
                                 </ul>
                              </div>
                           </div> */}
                           {/* <div className="col-lg-12 col-md-6">
                              <div className="widget">
                                 <h6 className="sidebar-title">Instagram Feeds</h6>
                                 <div className="dktags">
                                    <a className="single-tag" href="#">Popular</a>
                                    <a className="single-tag" href="#">Design</a>
                                    <a className="single-tag" href="#">Usability</a>
                                    <a className="single-tag" href="#">Icon</a>
                                    <a className="single-tag" href="#">Kit</a>
                                    <a className="single-tag" href="#">Business</a>
                                    <a className="single-tag" href="#">Keypad</a>
                                    <a className="single-tag" href="#">Tech</a>
                                 </div>
                              </div>
                           </div> */}

                           <div className="col-lg-12 col-md-12">
                              <div className="widget">
                                 <h6 className="sidebar-title">Related blog</h6>
                                 <div className="n-sidebar-feed">
                                    <ul>
                                       {relatedBlogQuery.data &&
                                          relatedBlogQuery.data.map((item, index) => (
                                             <li key={index}>
                                                <div className="feed-number">
                                                   <Link to={`/blog_details/${item.id}`}><img src={`${process.env.REACT_APP_API_BLOG_IMG}/${item.blog_img}`} alt="" /></Link>
                                                </div>
                                                <div className="feed-content">
                                                   <h6><Link to={`/blog_details/${item.id}`}>{item.blog_title}</Link></h6>
                                                   <span className="feed-date">
                                                      <i className="fa fa-calendar-alt"></i> {item.publish_date}
                                                   </span>
                                                </div>
                                             </li>
                                          ))}
                                    </ul>
                                 </div>
                              </div>
                           </div>
                           {/* <div className="col-lg-12 col-md-6">
                              <div className="widget sponsor">
                                 <h6 className="sidebar-title">Sponsor Adds</h6>
                                 <div className="sponsor-img">
                                    <img src={blogbanner11} alt="" className="img-fluid" />
                                    <div className="ad-width">270x240</div>
                                 </div>
                              </div>
                           </div> */}
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         {/* <!-- news-detalis-area-end  --> */}
      </div>
   )
}

export default Blog_details