import slbanner from "../viewer_assets/img/banner/sl-banner.jpg";
import smbanner from "../viewer_assets/img/banner/sl-banner-sm.png";
import { Link, useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { useSelector } from "react-redux";
import { useQuery } from "react-query";
import React, { useState, useEffect, useRef } from "react";
import LoadingBar from "react-top-loading-bar";
import { useDispatch } from "react-redux";
import { productByAddCart, productByFilter } from "../redux/productAction";
import { get } from "../utils/grtIdinlocal";
import { toastSuccess } from "../utils/notifyCustom"

const Shop = () => {
  useEffect(() => {
    window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth"
    })

}, [])
  const { itemId } = useParams();
  const navigate = useNavigate();
  const productData = useSelector((state) => state.productData);
  const cartData = useSelector((state) => state.cartData);
  const dispatch = useDispatch();
  const loadingBar = useRef(null);
  const [selectedOptions, setSelectedOptions] = useState({});

  const fetchProductFilter = async (item_id) => {
    const formData = new FormData();
    formData.append("item_id", item_id);

    const response = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_API_LOAD_PRODUCT_FILTER}`,
      formData
    );
    return response.data;
  };

  // const itemId = productData?.[0]?.item_id;

  console.log(itemId, "===========>>>>")

  // const subCategoriesQuery = useQuery(['subcategories', subCatId], () => fetchSubcategories(subCatId), {
  //     enabled: !!subCatId,
  // });

  const productFilterQuery = useQuery(
    ["productFilter", itemId],
    () => fetchProductFilter(itemId),
    {
      enabled: !!itemId,
    }
  );

  useEffect(() => {
    if (productFilterQuery.data) {
      const initialState = productFilterQuery.data.reduce((acc, option) => {
        acc[option.feature_name.toLowerCase()] = [];
        return acc;
      }, {});
      setSelectedOptions(initialState);
    }
  }, [productFilterQuery.data]);

  const handleCheckboxChange = (category, value) => {
    setSelectedOptions(prevOptions => {
      const categoryOptions = prevOptions[category] || [];
      return {
        ...prevOptions,
        [category]: categoryOptions.includes(value)
          ? categoryOptions.filter(option => option !== value)
          : [...categoryOptions, value],
      };
    });
  };
  console.log(selectedOptions);

  // Function to handle checkbox changes

  useEffect(() => {
    if (Object.values(selectedOptions).flat().length > 0) {
      console.log("call useEffect filter", Object.values(selectedOptions).flat().length > 0)
      console.log("call useEffect filter", Object.values(selectedOptions).flat())
      // vOnly dispatch if selectedOptions is not empty
      dispatch(productByFilter(itemId, selectedOptions));
    }
  }, [selectedOptions]);


  const startLoading = () => {
    loadingBar.current.continuousStart();
  };

  const finishLoading = () => {
    loadingBar.current.complete();
  };

  useEffect(() => {
    if (productFilterQuery.isLoading) {
      startLoading();
    } else {
      finishLoading();
    }
  }, [productFilterQuery.isLoading]);

  if (productFilterQuery.isLoading) {
    return (
      <div>
        <LoadingBar color="#f11946" ref={loadingBar} />
        {/* <h2>Loading...</h2> */}
      </div>
    );
  }

  console.log("yyyy", productFilterQuery.data);

  const hendleAddToCatd = async (id) => {
    if (cartData.find((cartItem) => +cartItem.id == id)) {
      navigate('/cart')
    } else {
    const cartIdsArr = get(id);
    console.log(cartIdsArr);
    dispatch(productByAddCart(cartIdsArr));
    toastSuccess("Successfully Add to cart")
    }
  };






  return (
    <main>
      {/* <!-- breadcrumb__area-start --> */}
      <LoadingBar color="#f11946" ref={loadingBar} />
      <section className="breadcrumb__area box-plr-75">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12">
              <div className="breadcrumb__wrapper">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to={"/"}>Home</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Shop
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- breadcrumb__area-end --> */}

      {/* <!-- shop-area-start --> */}
      <div className="shop-area mb-20">
        <div className="container">
          <div className="row">
            <div className="col-xl-3 col-lg-4">
              {/* <div className="product-widget mb-30">
                                <h5 className="pt-title">Product categories</h5>
                                <div className="widget-category-list mt-20">
                                    <form action="">
                                        <div>

                                            <div>
                                                {subCategoriesQuery.data && subCategoriesQuery.data.map((item) => (
                                                    <div key={item.id} className="single-widget-category">
                                                        <input type="checkbox" id={`cat-item-${item.id}`} name="cat-item" />
                                                        <label htmlFor={`cat-item-${item.id}`}>{item.name}</label>
                                                    </div>
                                                ))}
                                            </div>

                                        </div>
                                    </form>
                                </div>
                            </div> */}
              <div className="product-widget mb-30">
                <h5 className="pt-title">Filter By Price</h5>
                <div className="price__slider mt-30">
                  <div
                    id="slider-range"
                    className="ui-slider ui-corner-all ui-slider-horizontal ui-widget ui-widget-content"
                  >
                    <div
                      className="ui-slider-range ui-corner-all ui-widget-header"
                      style={{ left: "0%", width: "100%" }}
                    ></div>
                    <span
                      tabIndex="0"
                      className="ui-slider-handle ui-corner-all ui-state-default"
                      style={{ left: "0%" }}
                    ></span>
                    <span
                      tabIndex="0"
                      className="ui-slider-handle ui-corner-all ui-state-default"
                      style={{ left: "100%" }}
                    ></span>
                  </div>
                  <div>
                    <form action="#" className="s-form mt-20">
                      <input type="text" id="amount" readOnly="" />
                      <button type="submit" className="tp-btn-square-lg">
                        Filter
                      </button>
                    </form>
                  </div>
                </div>
              </div>
              {productFilterQuery
                ? productFilterQuery &&
                productFilterQuery.data &&
                productFilterQuery.data.map((filter, index) => (
                  <div key={index}>
                    {filter.feature_name === "Color" && (
                      <div className="product-widget mb-30 main_filter_holder">
                        <h5 className="pt-title">{filter.feature_name}</h5>
                        <div className="product__color mt-20">
                          <ul>
                            <li>
                              <a className="black"></a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    )}

                    {filter.feature_name !== "Color" && (
                      <div className="product-widget mb-30">
                        <h5 className="pt-title">{filter.feature_name}</h5>
                        <div className="widget-category-list mt-20 main_filter_holder">
                          <form action="">
                            {filter.feature_values
                              .split(",")
                              .map((value, index) => (
                                <div
                                  key={index}
                                  className="single-widget-category"
                                >
                                  <input

                                    id={`${filter.feature_name.replace(
                                      /[^A-Z0-9]/gi,
                                      "_"
                                    )}_${index}`}
                                    name="choose-item"
                                    nput
                                    type="checkbox"
                                    value={value}
                                    checked={(selectedOptions[filter.feature_name.toLowerCase()] || []).includes(value)}
                                    onChange={() => handleCheckboxChange(filter.feature_name.toLowerCase(), value)}
                                  />
                                  <label
                                    htmlFor={`${filter.feature_name.replace(
                                      /[^A-Z0-9]/gi,
                                      "_"
                                    )}_${index}`}
                                  >
                                    {value}
                                    {/* <i className="fa-solid fa-circle" /> */}
                                  </label>
                                </div>
                              ))}
                          </form>
                        </div>
                      </div>
                    )}
                  </div>
                ))
                : null}
            </div>
            <div className="col-xl-9 col-lg-8">
              {/* <div className="shop-banner mb-30">
                <div className="banner-image">
                  <img className="banner-l" src={slbanner} alt="logo" />
                  <img className="banner-sm" src={smbanner} alt="logo" />
                  <div className="banner-content text-center">
                    <p className="banner-text mb-30">
                      Hurry Up! <br /> Free Shipping All Order Over $99
                    </p>
                    <a href="shop.php" className="st-btn-d b-radius">
                      Discover now
                    </a>
                  </div>
                </div>
              </div> */}
              <div className="product-lists-top">
                <div className="product__filter-wrap">
                  <div className="row align-items-center">
                    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                      <div className="product__filter d-sm-flex align-items-center">
                        <div className="product__result pl-60">
                          <p>Showing 1-20 of 29 relults</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                      <div className="product__filter-right d-flex align-items-center justify-content-md-end">
                        <div className="product__sorting product__show-no">
                          <select>
                            <option>10</option>
                            <option>20</option>
                            <option>30</option>
                            <option>40</option>
                          </select>
                        </div>
                        <div className="product__sorting product__show-position ml-20">
                          <select>
                            <option>Latest</option>
                            <option>New</option>
                            <option>Up comeing</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="tab-content" id="productGridTabContent">
                <div
                  className="tab-pane fade  show active"
                  id="FourCol"
                  role="tabpanel"
                  aria-labelledby="FourCol-tab"
                >
                  <div className="tp-wrapper">
                    <div className="row g-0">
                      {productData.map((item, index) => (
                        <div
                          key={index}
                          className="col-xl-3 col-lg-4 col-md-6 col-sm-6"
                        >
                          <div
                            key={index}
                            className="product__item product__item-d"
                          >
                            <div className="product__thumb fix">
                              <div className="product-image w-img">
                                <Link
                                  to={`/product_details/${encodeURIComponent(
                                    item.title
                                  )}`}
                                >
                                  <img
                                    src={`${process.env.REACT_APP_API_PRODUCTS_IMG}/${item.main_img}`}
                                    alt="product"
                                  />
                                </Link>
                              </div>
                              <div className="product-action">
                                <Link to={`/product_details/${encodeURIComponent(
                                  item.title
                                )}`}>
                                  <a
                                    className="icon-box icon-box-1"
                                    data-bs-toggle="modal"
                                    data-bs-target="#productModalId"
                                  >
                                    <i className="fa fa-eye"></i>
                                    <i className="fa fa-eye"></i>
                                  </a>

                                </Link>
                                <a className="icon-box icon-box-1">
                                  <i className="fa fa-heart"></i>
                                  <i className="fa fa-heart"></i>
                                </a>
                                {/* <a className="icon-box icon-box-1">
                                  <i className="fa fa-layer-group"></i>
                                  <i className="fa fa-layer-group"></i>
                                </a> */}
                              </div>
                            </div>
                            <div className="product__content-3">
                              <h6>
                                <a className="a1">{`${item.cat_name}, ${item.item_name}`}</a>
                              </h6>
                              <h6>
                                <a className="a2">{item.name}</a>
                              </h6>
                              <div className="rating mb-5">
                                <ul>
                                  <li>
                                    <a href="#">
                                      <i className="fa fa-star"></i>
                                    </a>
                                  </li>
                                  <li>
                                    <a href="#">
                                      <i className="fa fa-star"></i>
                                    </a>
                                  </li>
                                  <li>
                                    <a href="#">
                                      <i className="fa fa-star"></i>
                                    </a>
                                  </li>
                                  <li>
                                    <a href="#">
                                      <i className="fa fa-star"></i>
                                    </a>
                                  </li>
                                  <li>
                                    <a href="#">
                                      <i className="fa fa-star"></i>
                                    </a>
                                  </li>
                                </ul>
                                {/* <span>(01 review)</span> */}
                              </div>
                              <div className="price mb-20">
                                <span>
                                  ₹{item.sell_price} - <del>₹{item.price}</del>
                                </span>
                              </div>
                            </div>
                            <div className="product__add-cart-s text-center">
                              <button
                                onClick={() => hendleAddToCatd(item.id)}
                                type="button"
                                className={
                                  `cart-btn d-flex mb-10 align-items-center justify-content-center w-100  ${
                                    cartData.find((cartItem) => +cartItem.id === +item.id)
                                      ? "cart-btn-3"
                                      : "cart-btn"
                                  }`
                                }
                              >
                                {cartData.find(
                                  (cartItem) => +cartItem.id == +item.id
                                )
                                  ? "view cart"
                                  : "Add to Cart"}
                              </button>
                              {/* <button type="button" className="wc-checkout d-flex align-items-center justify-content-center w-100" data-bs-toggle="modal" data-bs-target="#productModalId">
                                                                Quick View
                                                            </button> */}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="FiveCol"
                  role="tabpanel"
                  aria-labelledby="FiveCol-tab"
                >
                  <div className="tp-wrapper-2">
                    <div className="single-item-pd">
                      <div className="row align-items-center">
                        <div className="col-xl-9">
                          <div className="single-features-item single-features-item-df b-radius mb-20">
                            <div className="row  g-0 align-items-center">
                              <div className="col-md-4">
                                <div className="features-thum">
                                  <div className="features-product-image w-img">
                                    <a href="product-details.php">
                                      <img
                                        src="viewer_assets/img/product/sm-1.jpg"
                                        alt="logo"
                                      />
                                    </a>
                                  </div>
                                  <div className="product__offer">
                                    <span className="discount">-15%</span>
                                  </div>
                                  <div className="product-action">
                                    <a
                                      className="icon-box icon-box-1"
                                      data-bs-toggle="modal"
                                      data-bs-target="#productModalId"
                                    >
                                      <i className="fa fa-eye"></i>
                                      <i className="fa fa-eye"></i>
                                    </a>
                                    <a className="icon-box icon-box-1">
                                      <i className="fa fa-heart"></i>
                                      <i className="fa fa-heart"></i>
                                    </a>
                                    <a className="icon-box icon-box-1">
                                      <i className="fa fa-layer-group"></i>
                                      <i className="fa fa-layer-group"></i>
                                    </a>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-8">
                                <div className="product__content product__content-d">
                                  <h6>
                                    <a href="product-details.php">
                                      Classic Leather Backpack Daypack 2022
                                    </a>
                                  </h6>
                                  <div className="rating mb-5">
                                    <ul className="rating-d">
                                      <li>
                                        <a>
                                          <i className="fa fa-star"></i>
                                        </a>
                                      </li>
                                      <li>
                                        <a>
                                          <i className="fa fa-star"></i>
                                        </a>
                                      </li>
                                      <li>
                                        <a>
                                          <i className="fa fa-star"></i>
                                        </a>
                                      </li>
                                      <li>
                                        <a>
                                          <i className="fa fa-star"></i>
                                        </a>
                                      </li>
                                      <li>
                                        <a>
                                          <i className="fa fa-star"></i>
                                        </a>
                                      </li>
                                    </ul>
                                    <span>(01 review)</span>
                                  </div>
                                  <div className="features-des">
                                    <ul>
                                      <li>
                                        <a href="product-details.php">
                                          <i className="fas fa-circle"></i> Bass
                                          and Stereo Sound.
                                        </a>
                                      </li>
                                      <li>
                                        <a href="product-details.php">
                                          <i className="fas fa-circle"></i>{" "}
                                          Display with 3088 x 1440 pixels
                                          resolution.
                                        </a>
                                      </li>
                                      <li>
                                        <a href="product-details.php">
                                          <i className="fas fa-circle"></i>{" "}
                                          Memory, Storage &amp; SIM: 12GB RAM,
                                          256GB.
                                        </a>
                                      </li>
                                      <li>
                                        <a href="product-details.php">
                                          <i className="fas fa-circle"></i>{" "}
                                          Androi v10.0 Operating system.
                                        </a>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-3">
                          <div className="product-stock mb-15">
                            <h5>
                              Availability: <span> 940 in stock</span>
                            </h5>
                            <h6>
                              $220.00 - <del> $240.00</del>
                            </h6>
                          </div>
                          <div className="stock-btn ">
                            <button
                              type="button"
                              className="cart-btn d-flex mb-10 align-items-center justify-content-center w-100"
                            >
                              Add to Cart
                            </button>
                            <button
                              type="button"
                              className="wc-checkout d-flex align-items-center justify-content-center w-100"
                              data-bs-toggle="modal"
                              data-bs-target="#productModalId"
                            >
                              Quick View
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="single-item-pd">
                      <div className="row align-items-center">
                        <div className="col-xl-9">
                          <div className="single-features-item single-features-item-df b-radius mb-20">
                            <div className="row  g-0 align-items-center">
                              <div className="col-md-4">
                                <div className="features-thum">
                                  <div className="features-product-image w-img">
                                    <a href="product-details.php">
                                      <img
                                        src="viewer_assets/img/product/sm-2.jpg"
                                        alt="logo"
                                      />
                                    </a>
                                  </div>
                                  <div className="product-action">
                                    <a
                                      className="icon-box icon-box-1"
                                      data-bs-toggle="modal"
                                      data-bs-target="#productModalId"
                                    >
                                      <i className="fa fa-eye"></i>
                                      <i className="fa fa-eye"></i>
                                    </a>
                                    <a className="icon-box icon-box-1">
                                      <i className="fa fa-heart"></i>
                                      <i className="fa fa-heart"></i>
                                    </a>
                                    <a className="icon-box icon-box-1">
                                      <i className="fa fa-layer-group"></i>
                                      <i className="fa fa-layer-group"></i>
                                    </a>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-8">
                                <div className="product__content product__content-d">
                                  <h6>
                                    <a href="product-details.php">
                                      Samsang Galaxy A70 128GB Dual-SIM
                                    </a>
                                  </h6>
                                  <div className="rating mb-5">
                                    <ul className="rating-d">
                                      <li>
                                        <a>
                                          <i className="fa fa-star"></i>
                                        </a>
                                      </li>
                                      <li>
                                        <a>
                                          <i className="fa fa-star"></i>
                                        </a>
                                      </li>
                                      <li>
                                        <a>
                                          <i className="fa fa-star"></i>
                                        </a>
                                      </li>
                                      <li>
                                        <a>
                                          <i className="fa fa-star"></i>
                                        </a>
                                      </li>
                                      <li>
                                        <a>
                                          <i className="fa fa-star"></i>
                                        </a>
                                      </li>
                                    </ul>
                                    <span>(01 review)</span>
                                  </div>
                                  <div className="features-des">
                                    <ul>
                                      <li>
                                        <a href="product-details.php">
                                          <i className="fas fa-circle"></i> Bass
                                          and Stereo Sound.
                                        </a>
                                      </li>
                                      <li>
                                        <a href="product-details.php">
                                          <i className="fas fa-circle"></i>{" "}
                                          Display with 3088 x 1440 pixels
                                          resolution.
                                        </a>
                                      </li>
                                      <li>
                                        <a href="product-details.php">
                                          <i className="fas fa-circle"></i>{" "}
                                          Memory, Storage &amp; SIM: 12GB RAM,
                                          256GB.
                                        </a>
                                      </li>
                                      <li>
                                        <a href="product-details.php">
                                          <i className="fas fa-circle"></i>{" "}
                                          Androi v10.0 Operating system.
                                        </a>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-3">
                          <div className="product-stock mb-15">
                            <h5>
                              Availability: <span> 940 in stock</span>
                            </h5>
                            <h6>$216.00</h6>
                          </div>
                          <div className="stock-btn ">
                            <button
                              type="button"
                              className="cart-btn d-flex mb-10 align-items-center justify-content-center w-100"
                            >
                              Add to Cart
                            </button>
                            <button
                              type="button"
                              className="wc-checkout d-flex align-items-center justify-content-center w-100"
                              data-bs-toggle="modal"
                              data-bs-target="#productModalId"
                            >
                              Quick View
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="single-item-pd">
                      <div className="row align-items-center">
                        <div className="col-xl-9">
                          <div className="single-features-item single-features-item-df b-radius mb-20">
                            <div className="row  g-0 align-items-center">
                              <div className="col-md-4">
                                <div className="features-thum">
                                  <div className="features-product-image w-img">
                                    <a href="product-details.php">
                                      <img
                                        src="viewer_assets/img/product/sm-3.jpg"
                                        alt="logo"
                                      />
                                    </a>
                                  </div>
                                  <div className="product-action">
                                    <a
                                      className="icon-box icon-box-1"
                                      data-bs-toggle="modal"
                                      data-bs-target="#productModalId"
                                    >
                                      <i className="fa fa-eye"></i>
                                      <i className="fa fa-eye"></i>
                                    </a>
                                    <a className="icon-box icon-box-1">
                                      <i className="fa fa-heart"></i>
                                      <i className="fa fa-heart"></i>
                                    </a>
                                    <a className="icon-box icon-box-1">
                                      <i className="fa fa-layer-group"></i>
                                      <i className="fa fa-layer-group"></i>
                                    </a>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-8">
                                <div className="product__content product__content-d">
                                  <h6>
                                    <a href="product-details.php">
                                      Coffee Maker AH240a Full Function
                                    </a>
                                  </h6>
                                  <div className="rating mb-5">
                                    <ul className="rating-d">
                                      <li>
                                        <a>
                                          <i className="fa fa-star"></i>
                                        </a>
                                      </li>
                                      <li>
                                        <a>
                                          <i className="fa fa-star"></i>
                                        </a>
                                      </li>
                                      <li>
                                        <a>
                                          <i className="fa fa-star"></i>
                                        </a>
                                      </li>
                                      <li>
                                        <a>
                                          <i className="fa fa-star"></i>
                                        </a>
                                      </li>
                                      <li>
                                        <a>
                                          <i className="fa fa-star"></i>
                                        </a>
                                      </li>
                                    </ul>
                                    <span>(01 review)</span>
                                  </div>
                                  <div className="features-des">
                                    <ul>
                                      <li>
                                        <a href="product-details.php">
                                          <i className="fas fa-circle"></i> Bass
                                          and Stereo Sound.
                                        </a>
                                      </li>
                                      <li>
                                        <a href="product-details.php">
                                          <i className="fas fa-circle"></i>{" "}
                                          Display with 3088 x 1440 pixels
                                          resolution.
                                        </a>
                                      </li>
                                      <li>
                                        <a href="product-details.php">
                                          <i className="fas fa-circle"></i>{" "}
                                          Memory, Storage &amp; SIM: 12GB RAM,
                                          256GB.
                                        </a>
                                      </li>
                                      <li>
                                        <a href="product-details.php">
                                          <i className="fas fa-circle"></i>{" "}
                                          Androi v10.0 Operating system.
                                        </a>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-3">
                          <div className="product-stock mb-15">
                            <h5>
                              Availability: <span> 940 in stock</span>
                            </h5>
                            <h6>$125.00</h6>
                          </div>
                          <div className="stock-btn ">
                            <button
                              type="button"
                              className="cart-btn d-flex mb-10 align-items-center justify-content-center w-100"
                            >
                              Add to Cart
                            </button>
                            <button
                              type="button"
                              className="wc-checkout d-flex align-items-center justify-content-center w-100"
                              data-bs-toggle="modal"
                              data-bs-target="#productModalId"
                            >
                              Quick View
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="single-item-pd">
                      <div className="row align-items-center">
                        <div className="col-xl-9">
                          <div className="single-features-item single-features-item-df b-radius mb-20">
                            <div className="row  g-0 align-items-center">
                              <div className="col-md-4">
                                <div className="features-thum">
                                  <div className="features-product-image w-img">
                                    <a href="product-details.php">
                                      <img
                                        src="viewer_assets/img/product/sm-4.jpg"
                                        alt="logo"
                                      />
                                    </a>
                                  </div>
                                  <div className="product-action">
                                    <a
                                      className="icon-box icon-box-1"
                                      data-bs-toggle="modal"
                                      data-bs-target="#productModalId"
                                    >
                                      <i className="fa fa-eye"></i>
                                      <i className="fa fa-eye"></i>
                                    </a>
                                    <a className="icon-box icon-box-1">
                                      <i className="fa fa-heart"></i>
                                      <i className="fa fa-heart"></i>
                                    </a>
                                    <a className="icon-box icon-box-1">
                                      <i className="fa fa-layer-group"></i>
                                      <i className="fa fa-layer-group"></i>
                                    </a>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-8">
                                <div className="product__content product__content-d">
                                  <h6>
                                    <a href="product-details.php">
                                      Imported Wooden Felt Cushion Chair
                                    </a>
                                  </h6>
                                  <div className="rating mb-5">
                                    <ul className="rating-d">
                                      <li>
                                        <a>
                                          <i className="fa fa-star"></i>
                                        </a>
                                      </li>
                                      <li>
                                        <a>
                                          <i className="fa fa-star"></i>
                                        </a>
                                      </li>
                                      <li>
                                        <a>
                                          <i className="fa fa-star"></i>
                                        </a>
                                      </li>
                                      <li>
                                        <a>
                                          <i className="fa fa-star"></i>
                                        </a>
                                      </li>
                                      <li>
                                        <a>
                                          <i className="fa fa-star"></i>
                                        </a>
                                      </li>
                                    </ul>
                                    <span>(01 review)</span>
                                  </div>
                                  <div className="features-des">
                                    <ul>
                                      <li>
                                        <a href="product-details.php">
                                          <i className="fas fa-circle"></i> Bass
                                          and Stereo Sound.
                                        </a>
                                      </li>
                                      <li>
                                        <a href="product-details.php">
                                          <i className="fas fa-circle"></i>{" "}
                                          Display with 3088 x 1440 pixels
                                          resolution.
                                        </a>
                                      </li>
                                      <li>
                                        <a href="product-details.php">
                                          <i className="fas fa-circle"></i>{" "}
                                          Memory, Storage &amp; SIM: 12GB RAM,
                                          256GB.
                                        </a>
                                      </li>
                                      <li>
                                        <a href="product-details.php">
                                          <i className="fas fa-circle"></i>{" "}
                                          Androi v10.0 Operating system.
                                        </a>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-3">
                          <div className="product-stock mb-15">
                            <h5>
                              Availability: <span> 940 in stock</span>
                            </h5>
                            <h6>$160.00</h6>
                          </div>
                          <div className="stock-btn ">
                            <button
                              type="button"
                              className="cart-btn d-flex mb-10 align-items-center justify-content-center w-100"
                            >
                              Add to Cart
                            </button>
                            <button
                              type="button"
                              className="wc-checkout d-flex align-items-center justify-content-center w-100"
                              data-bs-toggle="modal"
                              data-bs-target="#productModalId"
                            >
                              Quick View
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="single-item-pd">
                      <div className="row align-items-center">
                        <div className="col-xl-9">
                          <div className="single-features-item single-features-item-df b-radius mb-20">
                            <div className="row  g-0 align-items-center">
                              <div className="col-md-4">
                                <div className="features-thum">
                                  <div className="features-product-image w-img">
                                    <a href="product-details.php">
                                      <img
                                        src="viewer_assets/img/product/sm-5.jpg"
                                        alt="logo"
                                      />
                                    </a>
                                  </div>
                                  <div className="product__offer">
                                    <span className="discount">-15%</span>
                                  </div>
                                  <div className="product-action">
                                    <a
                                      className="icon-box icon-box-1"
                                      data-bs-toggle="modal"
                                      data-bs-target="#productModalId"
                                    >
                                      <i className="fa fa-eye"></i>
                                      <i className="fa fa-eye"></i>
                                    </a>
                                    <a className="icon-box icon-box-1">
                                      <i className="fa fa-heart"></i>
                                      <i className="fa fa-heart"></i>
                                    </a>
                                    <a className="icon-box icon-box-1">
                                      <i className="fa fa-layer-group"></i>
                                      <i className="fa fa-layer-group"></i>
                                    </a>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-8">
                                <div className="product__content product__content-d">
                                  <h6>
                                    <a href="product-details.php">
                                      Sunhouse Decorative Lights – Imported
                                    </a>
                                  </h6>
                                  <div className="rating mb-5">
                                    <ul className="rating-d">
                                      <li>
                                        <a>
                                          <i className="fa fa-star"></i>
                                        </a>
                                      </li>
                                      <li>
                                        <a>
                                          <i className="fa fa-star"></i>
                                        </a>
                                      </li>
                                      <li>
                                        <a>
                                          <i className="fa fa-star"></i>
                                        </a>
                                      </li>
                                      <li>
                                        <a>
                                          <i className="fa fa-star"></i>
                                        </a>
                                      </li>
                                      <li>
                                        <a>
                                          <i className="fa fa-star"></i>
                                        </a>
                                      </li>
                                    </ul>
                                    <span>(01 review)</span>
                                  </div>
                                  <div className="features-des">
                                    <ul>
                                      <li>
                                        <a href="product-details.php">
                                          <i className="fas fa-circle"></i> Bass
                                          and Stereo Sound.
                                        </a>
                                      </li>
                                      <li>
                                        <a href="product-details.php">
                                          <i className="fas fa-circle"></i>{" "}
                                          Display with 3088 x 1440 pixels
                                          resolution.
                                        </a>
                                      </li>
                                      <li>
                                        <a href="product-details.php">
                                          <i className="fas fa-circle"></i>{" "}
                                          Memory, Storage &amp; SIM: 12GB RAM,
                                          256GB.
                                        </a>
                                      </li>
                                      <li>
                                        <a href="product-details.php">
                                          <i className="fas fa-circle"></i>{" "}
                                          Androi v10.0 Operating system.
                                        </a>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-3">
                          <div className="product-stock mb-15">
                            <h5>
                              Availability: <span> 940 in stock</span>
                            </h5>
                            <h6>$125.00</h6>
                          </div>
                          <div className="stock-btn ">
                            <button
                              type="button"
                              className="cart-btn d-flex mb-10 align-items-center justify-content-center w-100"
                            >
                              Add to Cart
                            </button>
                            <button
                              type="button"
                              className="wc-checkout d-flex align-items-center justify-content-center w-100"
                              data-bs-toggle="modal"
                              data-bs-target="#productModalId"
                            >
                              Quick View
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="single-item-pd">
                      <div className="row align-items-center">
                        <div className="col-xl-9">
                          <div className="single-features-item single-features-item-df b-radius mb-20">
                            <div className="row  g-0 align-items-center">
                              <div className="col-md-4">
                                <div className="features-thum">
                                  <div className="features-product-image w-img">
                                    <a href="product-details.php">
                                      <img
                                        src="viewer_assets/img/product/sm-6.jpg"
                                        alt="logo"
                                      />
                                    </a>
                                  </div>
                                  <div className="product-action">
                                    <a
                                      className="icon-box icon-box-1"
                                      data-bs-toggle="modal"
                                      data-bs-target="#productModalId"
                                    >
                                      <i className="fa fa-eye"></i>
                                      <i className="fa fa-eye"></i>
                                    </a>
                                    <a className="icon-box icon-box-1">
                                      <i className="fa fa-heart"></i>
                                      <i className="fa fa-heart"></i>
                                    </a>
                                    <a className="icon-box icon-box-1">
                                      <i className="fa fa-layer-group"></i>
                                      <i className="fa fa-layer-group"></i>
                                    </a>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-8">
                                <div className="product__content product__content-d">
                                  <h6>
                                    <a href="product-details.php">
                                      Korea Stainless Steel Pot Set 5 Piecs
                                    </a>
                                  </h6>
                                  <div className="rating mb-5">
                                    <ul className="rating-d">
                                      <li>
                                        <a>
                                          <i className="fa fa-star"></i>
                                        </a>
                                      </li>
                                      <li>
                                        <a>
                                          <i className="fa fa-star"></i>
                                        </a>
                                      </li>
                                      <li>
                                        <a>
                                          <i className="fa fa-star"></i>
                                        </a>
                                      </li>
                                      <li>
                                        <a>
                                          <i className="fa fa-star"></i>
                                        </a>
                                      </li>
                                      <li>
                                        <a>
                                          <i className="fa fa-star"></i>
                                        </a>
                                      </li>
                                    </ul>
                                    <span>(01 review)</span>
                                  </div>
                                  <div className="features-des">
                                    <ul>
                                      <li>
                                        <a href="product-details.php">
                                          <i className="fas fa-circle"></i> Bass
                                          and Stereo Sound.
                                        </a>
                                      </li>
                                      <li>
                                        <a href="product-details.php">
                                          <i className="fas fa-circle"></i>{" "}
                                          Display with 3088 x 1440 pixels
                                          resolution.
                                        </a>
                                      </li>
                                      <li>
                                        <a href="product-details.php">
                                          <i className="fas fa-circle"></i>{" "}
                                          Memory, Storage &amp; SIM: 12GB RAM,
                                          256GB.
                                        </a>
                                      </li>
                                      <li>
                                        <a href="product-details.php">
                                          <i className="fas fa-circle"></i>{" "}
                                          Androi v10.0 Operating system.
                                        </a>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-3">
                          <div className="product-stock mb-15">
                            <h5>
                              Availability: <span> 940 in stock</span>
                            </h5>
                            <h6>$99</h6>
                          </div>
                          <div className="stock-btn ">
                            <button
                              type="button"
                              className="cart-btn d-flex mb-10 align-items-center justify-content-center w-100"
                            >
                              Add to Cart
                            </button>
                            <button
                              type="button"
                              className="wc-checkout d-flex align-items-center justify-content-center w-100"
                              data-bs-toggle="modal"
                              data-bs-target="#productModalId"
                            >
                              Quick View
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="single-item-pd">
                      <div className="row align-items-center">
                        <div className="col-xl-9">
                          <div className="single-features-item single-features-item-df b-radius mb-20">
                            <div className="row  g-0 align-items-center">
                              <div className="col-md-4">
                                <div className="features-thum">
                                  <div className="features-product-image w-img">
                                    <a href="product-details.php">
                                      <img
                                        src="viewer_assets/img/product/sm-7.jpg"
                                        alt="logo"
                                      />
                                    </a>
                                  </div>
                                  <div className="product-action">
                                    <a
                                      className="icon-box icon-box-1"
                                      data-bs-toggle="modal"
                                      data-bs-target="#productModalId"
                                    >
                                      <i className="fa fa-eye"></i>
                                      <i className="fa fa-eye"></i>
                                    </a>
                                    <a className="icon-box icon-box-1">
                                      <i className="fa fa-heart"></i>
                                      <i className="fa fa-heart"></i>
                                    </a>
                                    <a className="icon-box icon-box-1">
                                      <i className="fa fa-layer-group"></i>
                                      <i className="fa fa-layer-group"></i>
                                    </a>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-8">
                                <div className="product__content product__content-d">
                                  <h6>
                                    <a href="product-details.php">
                                      HP Stainless Steel Hot Water Bottle
                                    </a>
                                  </h6>
                                  <div className="rating mb-5">
                                    <ul className="rating-d">
                                      <li>
                                        <a>
                                          <i className="fa fa-star"></i>
                                        </a>
                                      </li>
                                      <li>
                                        <a>
                                          <i className="fa fa-star"></i>
                                        </a>
                                      </li>
                                      <li>
                                        <a>
                                          <i className="fa fa-star"></i>
                                        </a>
                                      </li>
                                      <li>
                                        <a>
                                          <i className="fa fa-star"></i>
                                        </a>
                                      </li>
                                      <li>
                                        <a>
                                          <i className="fa fa-star"></i>
                                        </a>
                                      </li>
                                    </ul>
                                    <span>(01 review)</span>
                                  </div>
                                  <div className="features-des">
                                    <ul>
                                      <li>
                                        <a href="product-details.php">
                                          <i className="fas fa-circle"></i> Bass
                                          and Stereo Sound.
                                        </a>
                                      </li>
                                      <li>
                                        <a href="product-details.php">
                                          <i className="fas fa-circle"></i>{" "}
                                          Display with 3088 x 1440 pixels
                                          resolution.
                                        </a>
                                      </li>
                                      <li>
                                        <a href="product-details.php">
                                          <i className="fas fa-circle"></i>{" "}
                                          Memory, Storage &amp; SIM: 12GB RAM,
                                          256GB.
                                        </a>
                                      </li>
                                      <li>
                                        <a href="product-details.php">
                                          <i className="fas fa-circle"></i>{" "}
                                          Androi v10.0 Operating system.
                                        </a>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-3">
                          <div className="product-stock mb-15">
                            <h5>
                              Availability: <span> 940 in stock</span>
                            </h5>
                            <h6>$125.00</h6>
                          </div>
                          <div className="stock-btn ">
                            <button
                              type="button"
                              className="cart-btn d-flex mb-10 align-items-center justify-content-center w-100"
                            >
                              Add to Cart
                            </button>
                            <button
                              type="button"
                              className="wc-checkout d-flex align-items-center justify-content-center w-100"
                              data-bs-toggle="modal"
                              data-bs-target="#productModalId"
                            >
                              Quick View
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="single-item-pd">
                      <div className="row align-items-center">
                        <div className="col-xl-9">
                          <div className="single-features-item single-features-item-df b-radius mb-20">
                            <div className="row  g-0 align-items-center">
                              <div className="col-md-4">
                                <div className="features-thum">
                                  <div className="features-product-image w-img">
                                    <a href="product-details.php">
                                      <img
                                        src="viewer_assets/img/product/sm-8.jpg"
                                        alt="logo"
                                      />
                                    </a>
                                  </div>
                                  <div className="product-action">
                                    <a
                                      className="icon-box icon-box-1"
                                      data-bs-toggle="modal"
                                      data-bs-target="#productModalId"
                                    >
                                      <i className="fa fa-eye"></i>
                                      <i className="fa fa-eye"></i>
                                    </a>
                                    <a className="icon-box icon-box-1">
                                      <i className="fa fa-heart"></i>
                                      <i className="fa fa-heart"></i>
                                    </a>
                                    <a className="icon-box icon-box-1">
                                      <i className="fa fa-layer-group"></i>
                                      <i className="fa fa-layer-group"></i>
                                    </a>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-8">
                                <div className="product__content product__content-d">
                                  <h6>
                                    <a href="product-details.php">
                                      The North Face Womens Resolve 2 Jack
                                    </a>
                                  </h6>
                                  <div className="rating mb-5">
                                    <ul className="rating-d">
                                      <li>
                                        <a>
                                          <i className="fa fa-star"></i>
                                        </a>
                                      </li>
                                      <li>
                                        <a>
                                          <i className="fa fa-star"></i>
                                        </a>
                                      </li>
                                      <li>
                                        <a>
                                          <i className="fa fa-star"></i>
                                        </a>
                                      </li>
                                      <li>
                                        <a>
                                          <i className="fa fa-star"></i>
                                        </a>
                                      </li>
                                      <li>
                                        <a>
                                          <i className="fa fa-star"></i>
                                        </a>
                                      </li>
                                    </ul>
                                    <span>(01 review)</span>
                                  </div>
                                  <div className="features-des">
                                    <ul>
                                      <li>
                                        <a href="product-details.php">
                                          <i className="fas fa-circle"></i> Bass
                                          and Stereo Sound.
                                        </a>
                                      </li>
                                      <li>
                                        <a href="product-details.php">
                                          <i className="fas fa-circle"></i>{" "}
                                          Display with 3088 x 1440 pixels
                                          resolution.
                                        </a>
                                      </li>
                                      <li>
                                        <a href="product-details.php">
                                          <i className="fas fa-circle"></i>{" "}
                                          Memory, Storage &amp; SIM: 12GB RAM,
                                          256GB.
                                        </a>
                                      </li>
                                      <li>
                                        <a href="product-details.php">
                                          <i className="fas fa-circle"></i>{" "}
                                          Androi v10.0 Operating system.
                                        </a>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-3">
                          <div className="product-stock mb-15">
                            <h5>
                              Availability: <span> 940 in stock</span>
                            </h5>
                            <h6>$140.00</h6>
                          </div>
                          <div className="stock-btn ">
                            <button
                              type="button"
                              className="cart-btn d-flex mb-10 align-items-center justify-content-center w-100"
                            >
                              Add to Cart
                            </button>
                            <button
                              type="button"
                              className="wc-checkout d-flex align-items-center justify-content-center w-100"
                              data-bs-toggle="modal"
                              data-bs-target="#productModalId"
                            >
                              Quick View
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="tp-pagination text-center">
                <div className="row">
                  <div className="col-xl-12">
                    <div className="basic-pagination pt-30 pb-30">
                      <nav>
                        <ul>
                          <li>
                            <a href="shop.php" className="active">
                              1
                            </a>
                          </li>
                          <li>
                            <a href="shop.php">2</a>
                          </li>
                          <li>
                            <a href="shop.php">3</a>
                          </li>
                          <li>
                            <a href="shop.php">5</a>
                          </li>
                          <li>
                            <a href="shop.php">6</a>
                          </li>
                          <li>
                            <a href="shop.php">
                              <i className="fa fa-angle-double-right"></i>
                            </a>
                          </li>
                        </ul>
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- shop-area-end --> */}

      {/* <!-- shop modal start --> */}
      <div
        className="modal fade"
        id="productModalId"
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered product__modal"
          role="document"
        >
          <div className="modal-content">
            <div className="product__modal-wrapper p-relative">
              <div className="product__modal-close p-absolute">
                <button data-bs-dismiss="modal">
                  <i className="fa fa-times"></i>
                </button>
              </div>
              <div className="product__modal-inner">
                <div className="row">
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                    <div className="product__modal-box">
                      <div className="tab-content" id="modalTabContent">
                        <div
                          className="tab-pane fade show active"
                          id="nav1"
                          role="tabpanel"
                          aria-labelledby="nav1-tab"
                        >
                          <div className="product__modal-img w-img">
                            <img
                              src="viewer_assets/img/quick-view/quick-view-1.jpg"
                              alt="logo"
                            />
                          </div>
                        </div>
                        <div
                          className="tab-pane fade"
                          id="nav2"
                          role="tabpanel"
                          aria-labelledby="nav2-tab"
                        >
                          <div className="product__modal-img w-img">
                            <img
                              src="viewer_assets/img/quick-view/quick-view-2.jpg"
                              alt="logo"
                            />
                          </div>
                        </div>
                        <div
                          className="tab-pane fade"
                          id="nav3"
                          role="tabpanel"
                          aria-labelledby="nav3-tab"
                        >
                          <div className="product__modal-img w-img">
                            <img
                              src="viewer_assets/img/quick-view/quick-view-3.jpg"
                              alt="logo"
                            />
                          </div>
                        </div>
                        <div
                          className="tab-pane fade"
                          id="nav4"
                          role="tabpanel"
                          aria-labelledby="nav4-tab"
                        >
                          <div className="product__modal-img w-img">
                            <img
                              src="viewer_assets/img/quick-view/quick-view-4.jpg"
                              alt="logo"
                            />
                          </div>
                        </div>
                      </div>
                      <ul className="nav nav-tabs" id="modalTab" role="tablist">
                        <li className="nav-item" role="presentation">
                          <button
                            className="nav-link active"
                            id="nav1-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#nav1"
                            type="button"
                            role="tab"
                            aria-controls="nav1"
                            aria-selected="true"
                          >
                            <img
                              src="viewer_assets/img/quick-view/quick-nav-1.jpg"
                              alt="logo"
                            />
                          </button>
                        </li>
                        <li className="nav-item" role="presentation">
                          <button
                            className="nav-link"
                            id="nav2-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#nav2"
                            type="button"
                            role="tab"
                            aria-controls="nav2"
                            aria-selected="fase"
                          >
                            <img
                              src="viewer_assets/img/quick-view/quick-nav-2.jpg"
                              alt="logo"
                            />
                          </button>
                        </li>
                        <li className="nav-item" role="presentation">
                          <button
                            className="nav-link"
                            id="nav3-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#nav3"
                            type="button"
                            role="tab"
                            aria-controls="nav3"
                            aria-selected="fase"
                          >
                            <img
                              src="viewer_assets/img/quick-view/quick-nav-3.jpg"
                              alt="logo"
                            />
                          </button>
                        </li>
                        <li className="nav-item" role="presentation">
                          <button
                            className="nav-link"
                            id="nav4-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#nav4"
                            type="button"
                            role="tab"
                            aria-controls="nav4"
                            aria-selected="fase"
                          >
                            <img
                              src="viewer_assets/img/quick-view/quick-nav-4.jpg"
                              alt="logo"
                            />
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                    <div className="product__modal-content">
                      <h4>
                        <a href="product-details.php">
                          Samsung C49J89: £875, Debenhams Plus
                        </a>
                      </h4>
                      <div className="product__review d-sm-flex">
                        <div className="rating rating__shop mb-10 mr-30">
                          <ul>
                            <li>
                              <a>
                                <i className="fa fa-star"></i>
                              </a>
                            </li>
                            <li>
                              <a>
                                <i className="fa fa-star"></i>
                              </a>
                            </li>
                            <li>
                              <a>
                                <i className="fa fa-star"></i>
                              </a>
                            </li>
                            <li>
                              <a>
                                <i className="fa fa-star"></i>
                              </a>
                            </li>
                            <li>
                              <a>
                                <i className="fa fa-star"></i>
                              </a>
                            </li>
                          </ul>
                        </div>
                        <div className="product__add-review mb-15">
                          <span>01 review</span>
                        </div>
                      </div>
                      <div className="product__price">
                        <span>$109.00 – $307.00</span>
                      </div>
                      <div className="product__modal-des mt-20 mb-15">
                        <ul>
                          <li>
                            <a>
                              <i className="fas fa-circle"></i> Bass and Stereo
                              Sound.
                            </a>
                          </li>
                          <li>
                            <a>
                              <i className="fas fa-circle"></i> Display with
                              3088 x 1440 pixels resolution.
                            </a>
                          </li>
                          <li>
                            <a>
                              <i className="fas fa-circle"></i> Memory, Storage
                              & SIM: 12GB RAM, 256GB.
                            </a>
                          </li>
                          <li>
                            <a>
                              <i className="fas fa-circle"></i> Androi v10.0
                              Operating system.
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className="product__stock mb-20">
                        <span className="mr-10">Availability :</span>
                        <span>1795 in stock</span>
                      </div>
                      <div className="product__modal-form">
                        <form action="#">
                          <div className="pro-quan-area d-lg-flex align-items-center">
                            <div className="product-quantity mr-20 mb-25">
                              <div className="cart-plus-minus p-relative">
                                <input type="text" string="1" />
                              </div>
                            </div>
                            <div className="pro-cart-btn mb-25">
                              <button className="cart-btn" type="submit">
                                Add to cart
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                      <div className="product__stock mb-30">
                        <ul>
                          <li>
                            <a>
                              <span className="sku mr-10">SKU:</span>
                              <span>Samsung C49J89: £875, Debenhams Plus</span>
                            </a>
                          </li>
                          <li>
                            <a>
                              <span className="cat mr-10">Categories:</span>
                              <span>iPhone, Tablets</span>
                            </a>
                          </li>
                          <li>
                            <a>
                              <span className="tag mr-10">Tags:</span>
                              <span>Smartphone, Tablets</span>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- shop modal end --> */}
    </main>
  );
};

export default Shop;
