import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useDispatch } from "react-redux";
import { productByCategory } from "../redux/productAction";
function Sidenav({ data }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [useSubCat, setuseSubCat] = useState();
    const [loading, setLoading] = useState(false);
    console.log(data)
    const getSub = async (newSubCatId) => {
        setLoading(true);
        setuseSubCat("");
        const formData = new FormData();
        formData.append("sub_cat_id", newSubCatId);
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_API_GET_ITEMS}`,
                formData
            );
            setuseSubCat(response.data);
        } catch (error) {
            console.error("Error fetching sub categories:", error);
        } finally {
            setLoading(false);
        }
    };

    const navigateToShop = (subid, catid) => {
        console.log("catid", catid, "subid", subid);
        dispatch(productByCategory(catid, subid));
        navigate(`/shop/${subid}`);
    };

    return (
        <div>
            {/* <!-- //side-menu --> */}
            <div class="main-menu">
                <nav id="mobile-menu-2">
                    <ul>
                        {data.map((catitem, index) => (
                            <li key={index}>
                                <a className="active" onClick={() => getSub(catitem.id)}>{catitem.name} <i className="far fa-angle-down"></i></a>
                                <ul className="megamenu-1">
                                    {!loading && useSubCat && useSubCat.map((subitem, index) => (
                                        <ul className="mega-item" key={index}>
                                            <li><a onClick={() =>
                                                navigateToShop(subitem.id, catitem.id)
                                            }>{subitem.name}</a></li>

                                        </ul>
                                    ))}
                                </ul>
                            </li>
                        ))}
                    </ul>


                </nav>
            </div>

            {/* <!-- offcanvas area start --> */}
            <div class="offcanvas__area">
                <div class="offcanvas__wrapper">
                    <div class="offcanvas__close">
                        <button class="offcanvas__close-btn" id="offcanvas__close-btn">
                            <i class="fal fa-times"></i>
                        </button>
                    </div>
                    <div class="offcanvas__content">
                        <div class="offcanvas__logo mb-40">
                            <a href="index.html">
                                <img src="viewer_assets/img/logo/logo.png" alt="logo" height="24" />
                            </a>
                        </div>
                        <div class="offcanvas__search mb-25">
                            <form action="#">
                                <input type="text" placeholder="What are you searching for?" />
                                <button type="submit" ><i class="far fa-search"></i></button>
                            </form>
                        </div>

                        <div class="mobile-menu-2"></div>
                        <div class="offcanvas__action">
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- offcanvas area end -->       */}
            <div class="body-overlay"></div>
            {/* <!-- offcanvas area end --> */}
        </div>
    )
}

export default Sidenav