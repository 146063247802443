import React from 'react'
import wishlistbanner from '../viewer_assets/img/banner/page-banner-4.jpg'
import wishlistbanner1 from '../viewer_assets/img/cart/shop-p-10.jpg'
import wishlistbanner2 from '../viewer_assets/img/cart/shop-p-11.jpg'
import { Link } from 'react-router-dom'


function wishlist() {
  return (
    <div>
          {/* <!-- page-banner-area-start --> */}
    <div class="page-banner-area page-banner-height-2" style={{ backgroundImage:`url(${wishlistbanner})` }}>
        <div class="container">
            <div class="row">
                <div class="col-xl-12">
                    <div class="page-banner-content text-center">
                        <h4 class="breadcrumb-title">Wishlist</h4>
                        <div class="breadcrumb-two">
                            <nav>
                               <nav class="breadcrumb-trail breadcrumbs">
                                  <ul class="breadcrumb-menu">
                                     <li class="breadcrumb-trail">
                                        <Link to={"/"}><span>Home</span></Link>
                                     </li>
                                     <li class="trail-item">
                                        <span>Wishlist</span>
                                     </li>
                                  </ul>
                               </nav> 
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    {/* <!-- page-banner-area-end --> */}

    {/* <!-- cart-area-start --> */}
    <section class="cart-area pb-120 pt-120">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <form action="#">
                        <div class="table-content table-responsive">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th class="product-thumbnail">Images</th>
                                        <th class="cart-product-name">Product</th>
                                        <th class="product-price">Unit Price</th>
                                        <th class="product-quantity">Quantity</th>
                                        <th class="product-subtotal">Total</th>
                                        <th class="product-remove">Remove</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td class="product-thumbnail"><a href="shop-details.php"><img src={wishlistbanner1} alt=""/></a></td>
                                        <td class="product-name"><a href="shop-details.php">Light Jacket</a></td>
                                        <td class="product-price"><span class="amount">$130.00</span></td>
                                        <td class="product-quantity">
                                            <button class="tp-btn-h1" type="submit">Add To Cart</button>
                                        </td>
                                        <td class="product-subtotal"><span class="amount">$130.00</span></td>
                                        <td class="product-remove"><a href="#"><i class="fa fa-times"></i></a></td>
                                    </tr>
                                    <tr>
                                        <td class="product-thumbnail"><a href="shop-details.php"><img src={wishlistbanner2} alt=""/></a></td>
                                        <td class="product-name"><a href="shop-details.php">Pink Jacket</a></td>
                                        <td class="product-price"><span class="amount">$120.50</span></td>
                                        <td class="product-quantity">
                                            <button class="tp-btn-h1" type="submit">Add To Cart</button>
                                        </td>
                                        <td class="product-subtotal"><span class="amount">$120.50</span></td>
                                        <td class="product-remove"><a href="#"><i class="fa fa-times"></i></a></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </section>
     {/* <!-- cart-area-end --> */}
    </div>
  )
}

export default wishlist