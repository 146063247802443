export function combineFeatureValues(feature_names, feature_values) {
    var namesArray = feature_names.split("||");
    var valuesArray = feature_values.split("||");
    var resultArray = [];
  
    for (var i = 0; i < namesArray.length; i++) {
      resultArray.push(`${namesArray[i]} - ${valuesArray[i]}`);
    }
  
    return resultArray;
  }
  