import axios from "axios";
import { takeEvery, put, takeLatest } from "redux-saga/effects";
import {
  Set_Cart_Product_List,
  Product_List,
  Set_Product_List,
  Add_To_Cart,
  Remove_To_Cart,
  Product_List_With_filter,
} from "../redux/constant";

function* productsByCategory(data) {
  console.log("catid", data.catid, "subid", data.subid);
  try {
    const formData = new FormData();
    formData.append("cat_id", "2");
    formData.append("sub_cat_id", data.catid); // Use data.id for sub_cat_id
    formData.append("item_id", data.subid);

    const res = yield axios.post(
      `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_API_PRODUCT_LIST}`,
      formData
    );

    yield put({ type: Set_Product_List, data: res.data.product });
  } catch (e) {
    yield put({ type: "USER_FETCH_FAILED", message: e.message });
  }
}

function* productsByFilter(data) {
  console.log("catid", data.itemId, "subid", data.filterArr);
  try {
    const formData = new FormData();
    formData.append("item_id", data.itemId);
    formData.append("sort_order", "price_asc"); // Use data.id for sub_cat_id
    Object.entries(data.filterArr).forEach(([category, values]) => {
      values.forEach((value) => {
        formData.append(`filter[${category}_${category}][]`, value);
      });
    });

    const res = yield axios.post(
      `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_API_PRODUCT_LIST_WITH_FILTER}`,
      formData
    );

    yield put({ type: Set_Product_List, data: res.data.product });
  } catch (e) {
    yield put({ type: "USER_FETCH_FAILED", message: e.message });
  }
}

function* getCartProducts(data) {
  console.log("productid cart in saga", data.productid);
  try {
    const formData = new FormData();
    formData.append("all_product_id", data.productid);

    const res = yield axios.post(
      `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_API_LOAD_CART_ITEM}`,
      formData
    );

    yield put({ type: Set_Cart_Product_List, data: res.data.product });
  } catch (e) {
    yield put({ type: "USER_FETCH_FAILED", message: e.message });
  }
}

function* removeCartProducts(data) {
  console.log("productid cart in saga", data.productid);
  try {
    const formData = new FormData();
    formData.append("all_product_id", data.productid);

    const res = yield axios.post(
      `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_API_LOAD_CART_ITEM}`,
      formData
    );

    yield put({ type: Set_Cart_Product_List, data: res.data.product });
  } catch (e) {
    yield put({ type: "USER_FETCH_FAILED", message: e.message });
  }
}

// function* productCondition(data) {
//   console.log("saga", data.query);
//   try {
//     const res = yield axios.post(
//       process.env.REACT_APP_API_BASE_URL +
//         process.env.REACT_APP_API_CONDITIONS_PRODUCT,
//       {
//         condition_name: data.query,
//       }
//     );
//     yield put({ type: Set_Product_List, data: res.data.products });
//   } catch (e) {
//     yield put({ type: "USER_FETCH_FAILED", message: e.message });
//   }
// }

function* productSaga() {
  // yield takeLatest(Condition_Product, productCondition);
  yield takeEvery(Product_List, productsByCategory);
  yield takeLatest(Add_To_Cart, getCartProducts);
  yield takeLatest(Remove_To_Cart, removeCartProducts);
  yield takeLatest(Product_List_With_filter, productsByFilter);
}

export default productSaga;
