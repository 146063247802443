import React, { useEffect } from 'react'
// import bannerimg1 from '../../viewer_assets/img/banner/banner-17.jpg'
// import bannerimg2 from '../../viewer_assets/img/banner/banner-18.jpg'
// import bannerimg3 from '../../viewer_assets/img/banner/banner-19.jpg'
import Single_slider from './single_slider'
import axios from "axios";
import { useQuery } from "react-query";
import { Link } from "react-router-dom"
const fetchBanner = async () => {
    const formData = new FormData();
    formData.append("section", "banner");
    formData.append("holder", "3");
    const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_API_GET_BANNER}`,
        formData
    );
    return response.data;
};

const fetchBanner2 = async () => {
    const formData = new FormData();
    formData.append("section", "banner");
    formData.append("holder", "2");
    const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_API_GET_BANNER}`,
        formData
    );
    return response.data;
};



const Banner = () => {




    const bannerQuery2 = useQuery(["banner2"], () => fetchBanner(), {
        refetchOnMount: false,
        refetchOnWindowFocus: false
    });
    const bannerQuery3 = useQuery(["banner3"], () => fetchBanner2(), {
        refetchOnMount: false,
        refetchOnWindowFocus: false
    });

    useEffect(() => {
        // Fetch data when component mounts and when the query key changes
        bannerQuery2.refetch();
        bannerQuery3.refetch()
    }, [bannerQuery2.data, bannerQuery3.data]);

    return (
        <div className="slider-area light-bg-s pt-30">
            <div className="container 0">
                <div className="row">
                    <div className="col-xl-6">
                        <Single_slider />
                    </div>
                    <div className="col-xl-6">
                        <div className="row">
                            <div className="col-xl-6 col-lg-6">
                                <div className="row">
                                    {bannerQuery2.data &&
                                        bannerQuery2.data.map((item, index) => (
                                            <div className="col-xl-12 col-lg-12 col-sm-6" key={index}>
                                                <div className="banner__item p-relative w-img mb-30">
                                                    <div className="banner__img banner__img-2 b-radius-2">
                                                        <a >
                                                            <img
                                                                src={`${process.env.REACT_APP_API_BANNER_IMG}/${item.img}`}
                                                                alt="logo"
                                                                style={{ height: "240px", objectFit: "cover" }}
                                                            />
                                                        </a>
                                                    </div>
                                                    <div className="banner__content">

                                                        <h6><a >{item.heading}</a></h6>
                                                        <p className="banner-p mt-30">{item.description}</p>
                                                    </div>
                                                </div>
                                            </div>

                                        ))}
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-6">{bannerQuery3.data &&
                                bannerQuery3.data.map((item, index) => (
                                    <div className="banner__item p-relative w-img mb-30" key={index}>
                                        <div className="banner__img banner__img-3  b-radius-2">
                                            <a ><img src={`${process.env.REACT_APP_API_BANNER_IMG}/${item.img}`} alt="logo"
                                                style={{ height: "500px", objectFit: "cover" }}
                                            /></a>
                                        </div>
                                        <div className="banner__content banner__content-3">
                                            <div className="banner-df-btn mb-15">
                                                <a href="#" className="st-btn b-radius">HOT DEALS</a>
                                            </div>
                                            <p>Xbox Wireless</p>
                                            <h6 className="df-title"><a >Sale Up To 50% Off</a></h6>
                                            <div className="bottom-btn">
                                                <Link className="st-btn-border-2 b-radius">Discover now</Link>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Banner
