import React from 'react'

const Account = () => {
  return (
    <main>
  {/* cart-area-start */}
  <section className="cart-area py-lg-5 py-md-5 py-3">
    <div className="container">
      <div className="row">
        <div className="offset-lg-3 col-lg-6 col-md-12">
          <h5 className="mb-3">Your Account</h5>
          <div className="order_grid">
            <div className="order_box formTogle">
              <div className="">
                <h6 className="mb-2">
                  <i className="far fa-user" /> Mofijul Hasan Ali
                </h6>
                <h6 className="mb-2">
                  <i className="far fa-phone-alt" /> +91 1234567890
                </h6>
                <h6 className="mb-0">
                  <i className="far fa-envelope" /> yourname@gmail.com
                </h6>
              </div>
              <div>
                <a
                  href="javascript:void(0)"
                  onclick="$('.formTogle').toggle()"
                  style={{ fontSize: 15 }}
                  className="mb-0 text-primary"
                >
                  Edit
                </a>
              </div>
            </div>
            <form
              className="order_box form_inputs formTogle"
              style={{ display: "none" }}
            >
              <div className="row gy-3">
                <div className="col-lg-12">
                  <label>Your Name *</label>
                  <input
                    type="text"
                    className="form-control"
                    defaultValue="Mofijul Hasan Ali"
                    name=""
                    required=""
                  />
                </div>
                <div className="col-lg-5">
                  <label>Phone *</label>
                  <input
                    type="text"
                    className="form-control"
                    defaultValue="+91 1234567890"
                    name=""
                    required=""
                  />
                </div>
                <div className="col-lg-7">
                  <label>Email *</label>
                  <input
                    type="email"
                    className="form-control"
                    defaultValue="yourname@gmail.com"
                    name=""
                    required=""
                  />
                </div>
                <div className="col-12 text-end">
                  <button
                    type="button"
                    className="btn btn-md btn-dark"
                    onclick="$('.formTogle').toggle()"
                  >
                    Cancel
                  </button>
                  <button type="button" className="btn btn-md btn-primary">
                    Save Changes
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/* cart-area-end */}
</main>

  )
}

export default Account