import React from 'react'
import brandimg1 from '../../viewer_assets/img/brand/brand-1.jpg'
import brandimg2 from '../../viewer_assets/img/brand/brand-2.jpg'
import brandimg3 from '../../viewer_assets/img/brand/brand-3.jpg'
import brandimg4 from '../../viewer_assets/img/brand/brand-4.jpg'
import brandimg5 from '../../viewer_assets/img/brand/brand-5.jpg'
import brandimg6 from '../../viewer_assets/img/brand/brand-6.jpg'


import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const options = {
  margin: 30,
  responsiveClass: true,
  nav: false,
  dots: false,
  autoplay: true,
  loop:true,
  // navText: ["Prev", "Next"],
  autoplayTimeout:2500,
  smartSpeed: 1000,
  responsive: {
    0: {
      items: 1,
    },
    400: {
      items: 1,
    },
    600: {
      items: 1,
    },
    700: {
      items: 1,
    },
    1000: {
      items: 6,

    }
  },
};

function brand_slider() {
  return (
    <div>
        {/* <!-- brand-area-start --> */}
        <section className="brand-area brand-area-d">
            <div className="container">
                <div className="brand-slider swiper-container pt-50 pb-45">
                    <div className="swiper-wrapper">
                      <OwlCarousel className='slider-items owl-carousel' {...options}>
                          <div className='item'>
                            <div className="brand-item w-img swiper-slide">
                                <a href="#"><img src={brandimg1} alt="brand"/></a>
                            </div>
                          </div>
                          <div className='item'>
                            <div className="brand-item w-img swiper-slide">
                                <a href="#"><img src={brandimg2} alt="brand"/></a>
                            </div>
                          </div>
                          <div className='item'>
                            <div className="brand-item w-img swiper-slide">
                                <a href="#"><img src={brandimg3} alt="brand"/></a>
                            </div>
                          </div>
                          <div className='item'>
                            <div className="brand-item w-img swiper-slide">
                                <a href="#"><img src={brandimg4} alt="brand"/></a>
                            </div>
                          </div>
                          <div className='item'>
                            <div className="brand-item w-img swiper-slide">
                                <a href="#"><img src={brandimg5} alt="brand"/></a>
                            </div>
                          </div>
                          <div className='item'>
                            <div className="brand-item w-img swiper-slide">
                                <a href="#"><img src={brandimg6} alt="brand"/></a>
                            </div>
                          </div>
                      </OwlCarousel>;
                    </div>
                </div>
            </div>
        </section>
        {/* <!-- brand-area-end --> */}
    </div>
  )
}

export default brand_slider