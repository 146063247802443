import React, { useEffect } from 'react'
import axios from "axios";
import { useQuery } from "react-query";
import bannerarea1 from '../../viewer_assets/img/banner/banner-1.jpg'
import bannerarea2 from '../../viewer_assets/img/banner/banner-2.jpg'
import bannerarea3 from '../../viewer_assets/img/banner/banner-3.jpg'
const fetchBanner = async () => {
    const formData = new FormData();
    formData.append("section", "feature_bottom");
    formData.append("holder", "1");
    const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_API_GET_BANNER}`,
        formData
    );
    return response.data;
};
const fetchBanner2 = async () => {
    const formData = new FormData();
    formData.append("section", "feature_bottom");
    formData.append("holder", "2");
    const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_API_GET_BANNER}`,
        formData
    );
    return response.data;
};
const fetchBanner3 = async () => {
    const formData = new FormData();
    formData.append("section", "feature_bottom");
    formData.append("holder", "3");
    const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_API_GET_BANNER}`,
        formData
    );
    return response.data;
};

const Banner1 = () => {


    const bannerQuery1 = useQuery(["B1banner1"], () => fetchBanner(), {
        refetchOnMount: false,
        refetchOnWindowFocus: false
    });
    const bannerQuery2 = useQuery(["B1banner2"], () => fetchBanner2(), {
        refetchOnMount: false,
        refetchOnWindowFocus: false
    });
    const bannerQuery3 = useQuery(["B1banner3"], () => fetchBanner3(), {
        refetchOnMount: false,
        refetchOnWindowFocus: false
    });
    useEffect(() => {
        // Fetch data when component mounts and when the query key changes
        bannerQuery2.refetch();
        bannerQuery3.refetch()
        bannerQuery1.refetch()
    }, [bannerQuery2.data, bannerQuery3.data, bannerQuery1.data]);

    // console.log(bannerQuery1.data)
    return (
        <section className="banner__area light-bg-s pb-10">
            <div className="container">
                <div className="row">
                    <div className="col-xl-4 col-lg-4 col-md-6">
                        <div className="banner__item p-relative w-img mb-30">
                            <div className="banner__img b-radius-2">
                                <a ><img src={`${process.env.REACT_APP_API_BANNER_IMG}/${bannerQuery1.data?.[0]?.img}`} alt="logo" /></a>
                            </div>
                            <div className="banner__content">
                                <h6><a >{bannerQuery1.data?.[0]?.heading}</a></h6>
                                <p>{bannerQuery1.data?.[0]?.description}</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-6">
                        <div className="banner__item p-relative mb-30 w-img">
                            <div className="banner__img b-radius-2">
                                <a ><img src={`${process.env.REACT_APP_API_BANNER_IMG}/${bannerQuery1.data?.[0]?.img}`} alt="logo" /></a>
                            </div>
                            <div className="banner__content">
                                <h6><a >{bannerQuery1.data?.[0]?.heading}</a></h6>
                                <p>{bannerQuery1.data?.[0]?.description}</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-6">
                        <div className="banner__item p-relative mb-30 w-img">
                            <div className="banner__img b-radius-2">
                                <a ><img src={`${process.env.REACT_APP_API_BANNER_IMG}/${bannerQuery1.data?.[0]?.img}`} alt="logo" /></a>
                            </div>
                            <div className="banner__content">
                                <h6><a >{bannerQuery1.data?.[0]?.heading}</a></h6>
                                <p>{bannerQuery1.data?.[0]?.description}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Banner1