import React from 'react'

const changePassword = () => {
  return (
    <main>
  {/* cart-area-start */}
  <section className="cart-area py-lg-5 py-md-5 py-3">
    <div className="container">
      <div className="row">
        <div className="offset-lg-3 col-lg-6 col-md-12">
          <h5 className="mb-3">Your Account</h5>
          <div className="order_grid">
            <form className="order_box form_inputs">
              <div className="row gy-3">
                <div className="col-lg-12">
                  <label>Old Password *</label>
                  <input
                    type="password"
                    className="form-control"
                    name=""
                    required=""
                  />
                </div>
                <div className="col-lg-12">
                  <label>New Password *</label>
                  <input
                    type="password"
                    className="form-control"
                    name=""
                    required=""
                  />
                </div>
                <div className="col-lg-12">
                  <label>Confirm Password *</label>
                  <input
                    type="password"
                    className="form-control"
                    name=""
                    required=""
                  />
                </div>
                <div className="col-12 text-end">
                  {/* <button type="button" class="btn btn-md btn-dark" onclick="$('.formTogle').toggle()">Cancel</button> */}
                  <button type="button" className="btn btn-md btn-primary">
                    Save Changes
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/* cart-area-end */}
</main>

  )
}

export default changePassword