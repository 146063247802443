import React from 'react'

const Orders = () => {
    return (
        <main>
            {/* cart-area-start */}
            <section className="cart-area py-lg-5 py-md-5 py-3">
                <div className="container">
                    <div className="row">
                        <div className="offset-lg-2 col-lg-8 col-md-12">
                            <h5 className="mb-3">Order History</h5>
                            <div className="order_grid">
                                <a href="order-details.php" className="order_box">
                                    <div className="">
                                        <h6 className="mb-1">
                                            Order ID: MK4675467455 <small>(4 Products)</small>
                                        </h6>
                                        <p className="mb-0">Expected Delivery: Sat, 20th Jan, 2024</p>
                                    </div>
                                    <div>
                                        <h5 className="mb-0">₹12500</h5>
                                    </div>
                                </a>
                                <a href="order-details.php" className="order_box">
                                    <div className="">
                                        <h6 className="mb-1">
                                            Order ID: MK65245754 <small>(2 Products)</small>
                                        </h6>
                                        <p className="mb-0 text-success">
                                            Delivered: Sat, 20th Jan, 2024
                                        </p>
                                    </div>
                                    <div>
                                        <h5 className="mb-0">₹1500</h5>
                                    </div>
                                </a>
                                <a href="order-details.php" className="order_box">
                                    <div className="">
                                        <h6 className="mb-1">
                                            Order ID: MK65245754 <small>(2 Products)</small>
                                        </h6>
                                        <p className="mb-0 text-danger">
                                            Canceled: Sat, 20th Jan, 2024
                                        </p>
                                    </div>
                                    <div>
                                        <h5 className="mb-0">₹1500</h5>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* cart-area-end */}
        </main>

    )
}

export default Orders